import React from 'react';
import { Table } from 'reactstrap';
import './index.scss';

const HeaderItems = [
    {
        id: 'name',
        value: '',
    },
    {
        id: 'unique_calls',
        value: 'Unique Calls',
    },
    {
        id: 'calls',
        value: 'Calls',
    },
];

const VaritionsList = ({ data }) => {
  
    return (
        <div className="variations__list">
            <div className="variations__list__body">
                <Table responsive className="variations__list__body-table">
                    <thead>
                        <tr>
                            {
                                HeaderItems.map((item) => {
                                    return (
                                        <th key={item.id} className={`variations__list__body-table-header ${item.className}`}>{item.value}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (data.length > 0)
                                ?
                                data.length && data?.sort((a,b)=>a.id  - b.id).map((item) =>
                                    !item._newVariation &&
                                    <tr key={item.name} className="variations__list__body-row"  >
                                        <td>{item.name}</td>
                                        <td>{item.unique_visitors}</td>
                                        <td>{item.total_calls}</td>
                                    </tr>
                                )
                                :
                                    <tr className="not__started__content"><td colSpan="4" className="text-center cursor-pointer">Gate hasn't been started yet.</td></tr>
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
};

export default VaritionsList;
