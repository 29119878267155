import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import '../gates/gates.scss';
import { Box } from '@mui/system';
import Header from '../shared/header';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import styled from "styled-components"
import { Link } from 'react-router-dom';
import PlansService from "../../services/http/plansService";
import {getValue} from "../../services/storageService";
import {CircularProgress} from "@mui/material";
import moment from "moment/moment";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

const Plans = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paginatePlans, setPaginatePlans] = useState({});
    const [ activePlan, setActivePlan] = useState(null);

    const toggleVisibility=()=>{
        if(window.pageYOffset > 50){
            setIsVisible(true)
        }else{
            setIsVisible(false);
        }
    }

    const scrollToTop=()=>{
        window.scrollTo({
            top:0,
            behavior: 'smooth',
        })
    }

    const fetchPlans=(filter)=>{
        setLoading(true)
        const id = getValue('bussinessID')
        new PlansService().fetchPlans(id, filter).then(response => {
            const data = response.data.value.paginate_records;
            const paginationData = response.data.value.pagination_data;
            setPlans(data);
            if (data.filter((x)=> x.state === 'active')){
                setActivePlan(data.filter((x)=> x.state === 'active')[0])
            }
            console.log('plans', data);
            setLoading(false)
            setPaginatePlans(paginationData)
        }).catch(error => {
            setLoading(false)
            console.error(error);
        })

    }

    useEffect(() => {
        fetchPlans({per_page:30, page:1})
        window.addEventListener('scroll', toggleVisibility);
        return ()=>{
            window.removeEventListener('scroll', toggleVisibility);
        }
    }, []);

    const handlePageClick=(page)=>{
        fetchPlans({per_page:30, page: page.selected + 1});
    }

    return (
        <>
            <Header />
            <div className="dashboard">
                <div className="dashboard__body " id="dashBody">
                    <Div2>
                        <div className="col-4 mb-4 ">
                            <PlanUsageBox>
                                <div className="p-2">
                                    <div className="p-2 text-md-end">
                                        <P1>Usage this period (Unique Calls)</P1>
                                    </div>
                                    <PlanUsageDiv2 />
                                    { activePlan &&
                                        <div className="row p-2">
                                            <div className="col-6 pr-2">
                                                <P1>{activePlan.plan.name}</P1>
                                            </div>
                                            <div className="col-6">
                                                {`${activePlan.plan.monthly_calls}/${activePlan.used_visitor_quota}`}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </PlanUsageBox>
                        </div>
                        <div className="dashboard__body__list">
                            <div className="table__wrongUrl__container">
                                <Table responsive className="gates__list__body-table wrongUrl">
                                    <thead className='body__header wrongUrl'>
                                    <tr>
                                        <th key="id" className="gates__list__body-table-header">ID</th>
                                        <th key="plan" className="gates__list__body-table-header">Plan</th>
                                        <th key="account" className="gates__list__body-table-header">Account</th>
                                        <th key="start_date" className="gates__list__body-table-header">Start Date</th>
                                        <th key="end_date" className="gates__list__body-table-header">End Date</th>
                                        <th key="state" className="gates__list__body-table-header">State</th>
                                        <th key="used" className="gates__list__body-table-header">Usage</th>
                                    </tr>
                                    </thead>
                                    <tbody className='table__body'>
                                    {
                                        plans.length !== 0 &&
                                            <>
                                                {plans.map((plan, index)=>(
                                                    <>
                                                        <tr className='gates__list__body-row'>
                                                            <td className="gates__list__body-table-header">
                                                                <div className=''>
                                                                    {plan.id}
                                                                </div>
                                                            </td>
                                                            <td className="gates__list__body-table-header">
                                                                <div className=''>
                                                                    {plan.plan?.name}
                                                                </div>
                                                            </td>
                                                            <td className="gates__list__body-table-header">
                                                                <div className=''>
                                                                    {plan.business_account?.owner_email}
                                                                </div>
                                                            </td>
                                                            <td className="gates__list__body-table-header">
                                                                <div className=''>
                                                                    {moment(plan.start_date).format('MMM DD, YYYY')}
                                                                </div>
                                                            </td>
                                                            <td className="gates__list__body-table-header">
                                                                <div className=''>
                                                                    {moment(plan.end_date).format('MMM DD, YYYY')}
                                                                </div>
                                                            </td>
                                                            <td className="gates__list__body-table-header">
                                                                <div className=''>
                                                                    {plan.state}
                                                                </div>
                                                            </td>
                                                            <td className="gates__list__body-table-header">
                                                                <div className=''>
                                                                    {plan.used_visitor_quota_percentage} {' %'}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                            </>
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Div2>
                </div>
            </div>
        </>
    )
}

const PlanUsageBox = styled.div`
    background: #FFFFFF;
    border-radius: 8px;
`
const P1 = styled.div`
    font-weight: 700;
    color: #A4A6AB;
`
const PlanUsageDiv2 = styled.div`
    left: 4.4%;
    right: 6.36%;
    top: 36.51%;
    bottom: 63.49%;
    border: 2px solid rgba(196, 196, 196, 0.44);
`

const Div2 = styled.div`
    width: 100%;
    padding: 55px;
    margin: 0;
    background: transparent;
`
export default Plans;
