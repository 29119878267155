import React from 'react';
// import { Table } from 'reactstrap';
import Button from '../../shared/button'
import './index.scss';

// const HeaderItems = [
//     {
//         id: 'secret',
//         value: 'Secret',
//     },
//     {
//         id: 'api_key',
//         value: 'Api Key'
//     },
//     {
//         id: 'action',
//         value: ''
//     },
// ];

const CredentialsList = ({ data, onCreate, onRefresh }) => {
    return (
        <div className="credential__list">
            <div className="credential__list__body">
                        {
                            (Object.keys(data).length !== 0)
                                ?
                                <div key={data.id} className="credential__list__body__content">
                                    <div className="secret__content">
                                        <p>Secret</p>
                                        <p>{data.secret}</p>
                                    </div>
                                    <div className="apiKey__content">
                                        <p>API Key</p>
                                        <p>{data.api_key}</p>
                                    </div>
                                        <Button className="btn btn-success mx-3" style={{boxShadow:"none", backgroundColor: "#233dff" ,border: "1px solid #233dff !important", color:"#ffffff !important", textTransform: "capitalize", padding: "2px 26px", fontWeight: 600}}
                                            variant="contained"
                                            onClick={onRefresh}
                                        >
                                            Update
                                        </Button>
                                </div>
                                :
                                <tr onClick={onCreate}><td colSpan="4" className="text-center cursor-pointer">There is no API credential. Create a new API credential now</td></tr>
                        }
                {/* <Table responsive hover className="credential__list__body-table">
                    <thead>
                        <tr>
                            {
                                HeaderItems.map((item) => {
                                    return (
                                        <th key={item.id} className="credential__list__body-table-header">{item.value}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (Object.keys(data).length !== 0)
                                ?
                                <tr key={data.id} className="credential__list__body-row"  >
                                    <td>{data.secret}</td>
                                    <td>{data.api_key}</td>
                                    <td>
                                        <Button className="btn btn-primary m-0"
                                            variant="contained"
                                            onClick={onRefresh}
                                        >
                                            Refresh
                                        </Button>
                                    </td>
                                </tr>
                                :
                                <tr onClick={onCreate}><td colSpan="4" className="text-center cursor-pointer">There is no API credential. Create a new API credential now</td></tr>
                        }
                    </tbody>
                </Table> */}

            </div>
        </div>
    )
};

export default CredentialsList;
