import React, {useEffect} from "react";
const HubspotForm =({idName, portalId, formId})=> {

  useEffect(()=>{

    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: `${portalId}`,
          formId: `${formId}`,
          target: `#${idName}`
        })
      }
    });

  })
        return (
            <div>
              <div id={idName}></div>
            </div>
      );
    
  }

  export default HubspotForm
  