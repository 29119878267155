import axios from 'axios';
import { getValue } from '../storageService';
import { toast } from "react-toastify";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

class BaseService {
  constructor() {
    let service = axios.create({
      headers: {
        Authorization: `Bearer ${getValue('token')}`
      }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    if (error?.response?.data?.message?.toString().toLowerCase() === 'invalid token' || error?.response?.data?.message?.toString().toLowerCase() === 'Token is missing') {
      toast.error("Session Expired")
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('bussinessID');
      window.location.href = '/login'
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }

  handleCallback = (callback) => {
    return (response) => {
      callback && callback(response.status, response.data);
      return response;
    }
  }

  get(path, callback) {
    return this.service.get(BASE_API_URL + path).then(this.handleCallback(callback));
  }

  request = ({ method = "get", url, data, params, extra, callback }) => {
    const config = { url: BASE_API_URL + url, method, ...extra };
    const token = getValue('token');
    if (token) config.headers = { Authorization: `Bearer ${token}` };
    if (!["GET", "get"].includes(method) && data) config.data = data;
    if (params) config.params = params;
    return this.service.request({ ...config }).then(this.handleCallback(callback));
  };

  patch(path, payload, callback) {
    return this.service.request({
      method: 'PATCH',
      url: BASE_API_URL + path,
      responseType: 'json',
      data: payload
    }).then(this.handleCallback(callback));
  }

  post(path, payload, callback) {
    return this.service.request({
      method: 'POST',
      url: BASE_API_URL + path,
      responseType: 'json',
      data: payload
    }).then(this.handleCallback(callback));
  }

  put(path, payload, callback) {
    return this.service.request({
      method: 'PUT',
      url: BASE_API_URL + path,
      responseType: 'json',
      data: payload
    }).then(this.handleCallback(callback));
  }

  del(path, callback) {
    return this.service.request({
      method: 'DELETE',
      url: BASE_API_URL + path,
      responseType: 'json',
      data: {},
    }).then(this.handleCallback(callback));
  }
}

export default BaseService;
