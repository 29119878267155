import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: "b59679fc-fc02-41a4-bf63-b1467369a192",
    authority: "https://login.microsoftonline.com/b9b268bf-9130-4f20-9974-03d437db1206",
    redirectUri: "http://3.79.99.60/dashboard",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
export default msalInstance;
