import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './components/routes';
// import {StateProvider} from './store';
import React, { useEffect } from "react";

function App() {
    useEffect(() => {
        localStorage.removeItem('seenNotification')
    },[])
  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
