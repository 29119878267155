import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PasswordInput } from '../shared/input';
import { setValue } from '../../services/storageService';
// import GoogleButton from 'react-google-button';
import { MicrosoftLoginButton } from "react-social-login-buttons";
import { AppleLoginButton } from "react-social-login-buttons";
import { GoogleLogin } from 'react-google-login';
// import { UserAgentApplication } from "msal";
// import { useMsal, MsalProvider } from '@azure/msal-react';
import msalInstance from "./msalConfig";
import './signUp.scss';

const BusinessAccountSignup = () => {
  const history = useHistory();
  const [state, setState] = useState({
    name: '',
    owner_email: '',
    password: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [businessNameError, setBusinessNameError] = useState('');
  const [formError, setFormError] = useState('');
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  // const { instance } = useMsal();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value.trimStart(), // Remove leading spaces
    }));

    switch (id) {
      case 'name':
        validateBusinessName(value.trimStart());
        break;
      case 'owner_email':
        validateEmail(value.trimStart());
        break;
      case 'password':
        validatePassword(value.trimStart());
        break;
      case 'confirmPassword':
        validateConfirmPassword(value.trimStart());
        break;
      default:
        break;
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      setEmailError('Email is required');
    } else if (!emailPattern.test(email)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const validatePassword = (password) => {
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/; // Add this line
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;

    if (!password) {
      setPasswordError('Password is required');
    } else if (
      !uppercaseRegex.test(password) ||
      !lowercaseRegex.test(password) || // Add this line
      !specialCharRegex.test(password) ||
      !numberRegex.test(password) ||
      password.length < 8
    ) {
      setPasswordError(
        'Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number'
      );
    } else {
      setPasswordError('');
    }
  };

  const validateConfirmPassword = (confirmPassword) => {
    if (!confirmPassword) {
      setConfirmPasswordError('Confirm password is required');
    } else if (confirmPassword !== state.password) {
      setConfirmPasswordError('Passwords do not match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const validateBusinessName = (name) => {
    const namePattern = /^[A-Za-z][A-Za-z0-9 ]*$/;

    if (!name) {
      setBusinessNameError('Company name is required');
    } else if (!namePattern.test(name)) {
      setBusinessNameError('Company name must start with a letter and contain only letters, numbers, and spaces');
    } else {
      setBusinessNameError('');
    }
  };

  const onSignupClick = () => {
    validateEmail(state.owner_email);
    validatePassword(state.password);
    validateConfirmPassword(state.confirmPassword);
    validateBusinessName(state.name);

    if (state.password !== state.confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
      return;
    }

    if (
      !state.name ||
      !state.owner_email ||
      !state.password ||
      !state.confirmPassword ||
      emailError ||
      passwordError ||
      confirmPasswordError ||
      businessNameError
    ) {
      toast.error(
        'Please fill in all required fields and fix validation errors before signing up'
      );
      return;
    }

    setFormError('');
    handleFreePlanSignup();
  };

  const handleFreePlanSignup = async () => {
    setLoading(true);

    try {
      const { name, owner_email, contact_number, password } = state;

      const requestBody = {
        business_account: {
          name,
          owner_email,
          contact_number,
          password
        }
      };

      const response = await fetch(`${baseUrl}/api/business_accounts/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to sign up. Please try again.');
      }

      const data = await response.json();

      toast.success('Sign up successful');
      setValue('token', data.value.token);
      setValue('email', state.owner_email)
      setValue('billing_type', data.value.billing_type)
      history.push({
        pathname: '/dashboard',
        state: { welcome: true }
      });
    } catch (error) {
      console.error('Sign up error:', error);
      setFormError(error.message || 'Failed to sign up. Please try again.');
      toast.error(error.message || 'Failed to sign up. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignup = async (response) => {
    if (!response || !response.tokenId) return toast.error('Google signup failed');
    try {
      const res = await fetch(`${baseUrl}/api/business_accounts/social_media_signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          business_account: {
            provider: 'google',
            token: response.tokenId,
            name: response.profileObj.givenName,
            last_name: response.profileObj.familyName,
            email: response.profileObj.email,
            contact_number: state.contact_number,
          },
        }),
      });
      const data = await response.json();

      toast.success('Sign up successful');
      setValue('token', data.value.token)
      setValue('email', response.profileObj.email)
      history.push({
        pathname: '/dashboard',
        state: { welcome: true }
      });
    } catch (error) {
      toast.error(error.message || 'Google signup failed');
    }
  };

  const handleMicrosoftSignup = async () => {
    try {
      const response = await msalInstance.loginPopup({
        scopes: ["user.read"],
      });
      const res = await fetch(`${baseUrl}/api/business_accounts/social_media_signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          business_account: {
            provider: 'microsoft',
            // token: accessToken,
            name: response.account.name,
            email: response.account.username,
            contact_number: state.contact_number,
          },
        }),
      });
      const data = await res.json();
      toast.success('Sign up successful');
      setValue('token', data.value.token);
      history.push({
        pathname: '/dashboard',
        state: { welcome: true }
      });
    } catch (error) {
      console.error("Microsoft signup error:", error);
      toast.error("Failed to sign up with Microsoft. Please try again.");
    }
  };

  const handleAppleSignup = async (response) => {
    try {
      // const { authorizationCode } = response;
      // const appleResponse = await axios.post('/api/auth/apple', { authorizationCode });
      // const { data } = appleResponse;
      // console.log(data);
      // Handle successful Apple sign-up response
    } catch (error) {
      // setError(error.message);
    }
  };

  const handleSubmit = async (event) => {
    // event.preventDefault();
    // try {
    //   const response = await axios.post('/api/auth/register', {
    //     email,
    //     password,
    //     companyName,
    //   });
    //   const { data } = response;
    //   console.log(data);
    //   // Handle successful sign-up response
    // } catch (error) {
    //   setError(error.message);
    // }
  };


  return (
    <div className="business-account-signup">
      <div className="business-account-signup__header">
        <div className="business-account-signup__kontoro-labs">
          Kontoro Labs
        </div>
        <div className="business-account-signup__title-text">
          Create an account
        </div>
      </div>

      <div className="business-account-signup__form">
        <form>
          <div className="input-container">
            <input
              type="email"
              className={`business-account-signup__input ${
                emailError ? 'error' : ''
              }`}
              id="owner_email"
              placeholder="Email address *"
              value={state.owner_email}
              onChange={handleChange}
              required
            />
            {emailError && <p className="error-message">{emailError}</p>}
          </div>
          <div className="input-container">
            <PasswordInput
              className={`business-account-signup__input ${
                passwordError ? 'error' : ''
              }`}
              id="password"
              placeholder="Password *"
              value={state.password}
              onChange={handleChange}
              required
            />
            {passwordError && <p className="error-message">{passwordError}</p>}
          </div>
          <div className="input-container">
            <PasswordInput
              className={`business-account-signup__input ${
                confirmPasswordError ? 'error' : ''
              }`}
              id="confirmPassword"
              placeholder="Confirm Password *"
              value={state.confirmPassword}
              onChange={handleChange}
              required
            />
            {confirmPasswordError && (
              <p className="error-message">{confirmPasswordError}</p>
            )}
          </div>
          <div className="input-container">
            <input
              type="text"
              className={`business-account-signup__input ${
                businessNameError ? 'error' : ''
              }`}
              id="name"
              placeholder="Company name *"
              value={state.name}
              onChange={handleChange}
              required
              pattern="[A-Za-z][A-Za-z0-9 ]*"
              title="Company name must start with a letter and contain only letters, numbers, and spaces"
            />

            {businessNameError && (
              <p className="error-message">{businessNameError}</p>
            )}
          </div>
          {formError && <p className="form-error">{formError}</p>}
          <button
            type="button"
            className="business-account-signup__button"
            onClick={onSignupClick}
            disabled={loading}
          >
            {loading ? 'Signing up...' : 'Continue'}
          </button>
        </form>
        <div className="login-options">
          Already have an account? <a href="/login">Login</a>
        </div>
        <div class="line"></div>
        <div className="social-media-login">
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Continue with Google"
            onSuccess={handleGoogleSignup}
            onFailure={(error) => console.error('Google signup error:', error)}
            cookiePolicy={'single_host_origin'}
            className="google-login-button"
            style={{ padding: '6px' }}
          />

          <MicrosoftLoginButton
            onClick={handleMicrosoftSignup}
            style={{
                background: 'rgb(255, 255, 255)',
                boxShadow:'none',
                display: 'inline-flex',
                color: 'rgba(0, 0, 0, 0.54)',
                padding: '0px',
                borderRadius: '5px',
                border: '1px solid #3C4043',
                fontSize: '14px',
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
                width: 272
              }}
          >
            <span>Continue with Microsoft Account</span>
          </MicrosoftLoginButton>

          <AppleLoginButton
          onClick={handleAppleSignup}
          style={{
              background: 'rgb(255, 255, 255)',
              boxShadow:'none',
              display: 'inline-flex',
              color: 'rgba(0, 0, 0, 0.54)',
              padding: '0px',
              borderRadius: '5px',
              border: '1px solid #3C4043',
              fontSize: '14px',
              fontWeight: '500',
              fontFamily: 'Roboto, sans-serif',
              width: 272
            }}
          >
            <span>Continue with Apple</span>
          </AppleLoginButton>

        </div>
        <div className="terms">
          <p>
            <a href="/terms">Terms of Use</a> | <a href="/privacy">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BusinessAccountSignup;
