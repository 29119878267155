import React, { useEffect, useRef } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import "./index.scss";
import { Menu, MenuItem, IconButton, Dialog, DialogContent, Box } from "@mui/material";
import { Button } from "reactstrap";
import { styled, alpha } from "@mui/material/styles";
import { delelteValue, getValue } from "../../../services/storageService";
import { UserIcon } from "../../../assets/icons";
import logo from "../../../assets/images/Logo.png";
import NotificationBar from "./notificationBar";
import PlansService from "../../../services/http/plansService";

const Heading = styled("div")({
  fontSize: "18px",
  letterSpacing: "1px",
  color: "#4C4F53",
  fontWeight: " 400",
  backgroundColor: "#ffffff",
  userSelect: "none",
});

const Header = ({ isUpdated, setCheckSave, closeDrawer }) => {
  const history = useHistory();
  const location = useLocation();
  const anchorRef = useRef(null);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [planUsed, setPlanUsed] = React.useState(false);
  const [currentPlan, setCurrentPlan] = React.useState({});
  const [endDate, setEndDate] = React.useState({});
  const billingType = getValue("billing_type");

  const handleLogout = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    delelteValue("token");
    delelteValue("email");
    delelteValue("bussinessID");
    history.push("/login");
  };

  const fetchPlansUsedDetail = () => {
    const id = getValue("bussinessID");
    new PlansService()
      .planUsed(id)
      .then((response) => {
        const data = response.data;
        if (data) {
          setPlanUsed(data.used_visitor_quota_percentage);
          setCurrentPlan(data.plan);
          setEndDate(data.end_date);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchPlansUsedDetail();
  }, [planUsed]);

  const handleClose = () => {
    setOpenPopUp(false);
  };

  function getDaysLeft(endDate) {
    const end = new Date(endDate);
    const today = new Date();
    
    const differenceInTime = end - today;
    
    return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
  }

  return (
    <Heading>
      <NotificationBar planUsed={planUsed} show />
        <div
          className="header_container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 72px 0px 75px",
            height: "73px",
          }}
        >

        <div style={{ display: "flex", alignItems: "center" }}>
          {isUpdated ? (
            <span
              className="kontoro-labs"
              onClick={isUpdated ? () => setCheckSave(true) : () => closeDrawer()}
            >
              Kontoro Labs
            </span>
          ) : (
            <span className="kontoro-labs">
              <Link to="/">Kontoro Labs</Link>
            </span>
          )}

          {currentPlan.name && currentPlan.is_free && (
            <span className="current-plan" style={{ marginLeft: "10px" }}>
              <span>{currentPlan.name}, </span>
              <span>{getDaysLeft(endDate)} days left</span>
            </span>
          )}
        </div>

        <div className="links-group" style={{ display: "flex", justifyContent: "center" }}>
          <Link
            style={{ textDecoration: "none", color: "black" , zIndex: "1000"}}
            className={`BOXXX ${location.pathname === "/dashboard" ? "SBOX" : "BOXXX"}`}
            to="/dashboard"
          >
            <span>Gates</span>
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" , zIndex: "1000"}}
            className={`BOXXX ${location.pathname === "/log-details" ? "SBOX" : "BOXXX"}`}
            to="/log-details"
          >
            <span>Logs</span>
          </Link>
          {/*<Link style={{ textDecoration: "none", color: "black" }} className={`BOXXX ${location.pathname === "/plans" ? "SBOX" : ""}`} to="/plans">
            <span>Plan</span>
          </Link>*/}
          {billingType === "manual" ? (
            <Link
              style={{ textDecoration: "none", color: "black" }}
              className={`BOXXX ${location.pathname === "/plans" ? "SBOX" : "BOXXX"}`}
              to="/plans"
            >
              <span>Account</span>
            </Link>
          ) : (
            <Link
              style={{ textDecoration: "none", color: "black" }}
              className={`BOXXX ${location.pathname === "/subscription-details" ? "SBOX" : "BOXXX"}`}
              to="/subscription-details"
            >
              <span>Account</span>
            </Link>
          )}
          <Link
            style={{ textDecoration: "none", color: "black" }}
            className={`BOXXX ${location.pathname === "/api-list" ? "SBOX" : "BOXXX"}`}
            to="/api-list"
          >
            <span>Settings</span>
          </Link>
        </div>
        <IconButton onClick={() => setOpenPopUp(true)} style={{ padding: "0" }}>
          <UserIcon />{" "}
          <span style={{ fontSize: "16px", marginLeft: "9px", color: "#4C4F53" }}></span>
        </IconButton>
      </div>
      <Dialog open={openPopUp} onClose={handleClose} className="logout__popup">
        <DialogContent className="logout__content">
          <Box width="sm">
            <p
              className="dialog__subtitle"
              style={{ fontWeight: "700", fontSize: "18px", textAlign: "center" }}
            >
              Are you sure you want to log out ?
            </p>
            <div className="cta__logout">
              <Button
                style={{
                  boxShadow: "none",
                  backgroundColor: "#ffffff",
                  border: "1px solid #D5D9FE",
                  marginRight: "13px",
                  color: "#233dff",
                  textTransform: "capitalize",
                  padding: "2px 26px",
                  fontWeight: 500,
                  height: "100%",
                }}
                onClick={() => setOpenPopUp(false)}
              >
                Close
              </Button>
              <Button
                style={{
                  boxShadow: "none",
                  backgroundColor: "#233dff",
                  border: "1px solid #233dff",
                  color: "#ffffff",
                  textTransform: "inherit",
                  padding: "2px 26px",
                  fontWeight: 600,
                  height: "100%",
                  letterSpacing: "0.25px",
                }}
                onClick={handleLogout}
              >
                Log out
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </Heading>
  );
};

export default Header;
