export const checkPassword = (password, name = "Password") => {
    switch (Boolean(password)) {
        case password.length < 8:
            return name + " must be use 8 or more characters";
        // eslint-disable-next-line
        case !/^(?=.*[a-z])/.test(password):
            return name + " must include lowercase letter";
        // eslint-disable-next-line
        case !/^(?=.*[A-Z])/.test(password):
            return name + " must include uppercase letter";
        // eslint-disable-next-line
        case !/^(?=.*[0-9])/.test(password):
            return name + " must include numbers";
        // eslint-disable-next-line
        case !/^(?=.*[!@#\$%\^&\*])/.test(password):
            return name + " must include special character";
        default:
            return true;
    }
}
