import BaseService from "./baseService";

class SubscriptionService extends BaseService {
  fetchSubscriptionDetails() {
    return this.get('/api/subscriptions/show');
  }

  fetchPlans() {
    return this.get('/api/plans/plan_list');
  }

  stopSubscription() {
    return this.post(`/api/subscriptions/stop_subscription`);
  }

  changeSubscriptionPlan(newPlanId) {
    return this.post(`/api/subscriptions/change_subscription_plan`, { planId: newPlanId });
  }

  reactivateSubscription() {
    return this.post(`/api/subscriptions/reactivate_subscription`);
  }

  createBillingPortalSession(customerId, returnUrl) {
    return this.post('/api/subscriptions/create_billing_portal_session', {
      customerId,
      returnUrl,
    });
  }

  createCheckoutSession(planId, discount = 0) {
    return this.post('/api/subscriptions/create_checkout_session', { plan_id: planId, discount: discount });
  }
}

export default SubscriptionService;
