import React, { useState } from 'react'
import { Dialog as MuiDialog, DialogTitle, DialogContent, Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import './index.scss'

const Dialog = ({ open, onClose, titleProps, titleClass, bodyClass,loading = false, children }) => {
    return (
        <MuiDialog open={open} onClose={onClose} className="dialog__container" >
            {loading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', width:'100%', height: '100%',zIndex: 9 }}>
                <CircularProgress color="inherit" />
            </Box>}
            <DialogTitle className={titleClass}>
                {titleProps}
            </DialogTitle>
            <DialogContent className={bodyClass}>
                {children}
            </DialogContent>
        </MuiDialog>
    )
}

Dialog.propTypes = {
    children: PropTypes.node,
    titleProps: PropTypes.node,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    titleClass: PropTypes.string,
    bodyClass: PropTypes.string,
    loading: PropTypes.bool,
};

const ConfirmationDialog = ({ title, titleClass, bodyClass, msg, cta }) => {
    const [open, setOpen] = useState(true)
    const onClose = () => setOpen(false)
    return (
        <MuiDialog open={open} onClose={onClose} className="confirmation__dialog__container" >
            <DialogTitle className={titleClass}>
                <div className="update__gate pb-0">
                    <span className="update__gate__title-text">{title}</span>
                </div>
            </DialogTitle>
            <DialogContent className={bodyClass}>
                <Box className="update__gate__body my-3" width="sm">
                    {msg}
                </Box>
                {cta}
            </DialogContent>
        </MuiDialog>
    )
}

ConfirmationDialog.propTypes = {
    children: PropTypes.node,
    titleProps: PropTypes.node,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    titleClass: PropTypes.string,
    bodyClass: PropTypes.string,
};


export { Dialog as default, ConfirmationDialog }
