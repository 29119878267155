import React, { useState, useEffect } from 'react';
import { Button } from "reactstrap";
import { LinearProgress } from '@mui/material';
import LoginService from '../../services/http/loginService';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getValue } from '../../services/storageService';
import './login.scss';
import { toast, ToastContainer } from 'react-toastify';
import { checkPassword } from '../../utils';
import { PasswordInput } from '../shared/input';

const Login = () => {
  const history = useHistory();

  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  useEffect(() => {
    if (getValue('token')) history.push('/')
  }, [history])

  const [state, setState] = useState({
    passwordConfirm: "",
    password: "",
    passwordConfirmError: "",
    passwordError: ""
  });
  
  const handleChange = (e) => {
    setApiError('')
    const { id, value } = e.target;
    setState(prevState => ({
      ...prevState,
      passwordError: "",
      passwordConfirmError: "",
      [id]: value
    }))
  };
  
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false)
  
  const { passwordConfirm, password, passwordError, passwordConfirmError } = state;
    const isValidForm = () => {
      let res = true
      if (password === "") {
          setState(prevState => ({
              ...prevState,
              passwordError: "This is required.",
          }))
          res = false
      } else {
          let err = checkPassword(password, 'Password')
          if(err !== true) {
              return setState(prevState => ({
                  ...prevState,
                  passwordError: err,
              }))
          }
          // res = false
      }
      
      if (passwordConfirm === "") {
          setState(prevState => ({
              ...prevState,
              passwordError: "This is required.",
          }))
          res = false
      } else {
          let err = checkPassword(passwordConfirm, 'Confirm Password')
          if(err !== true) {
              setState(prevState => ({
                  ...prevState,
                  passwordConfirmError: err,
              }))
              res = false
          }
      }

      if(res && password !== passwordConfirm ){
        setState(prevState => ({
            ...prevState,
            passwordError: "Password and Confirm Password should be the same.",
            passwordConfirmError: "Password and Confirm Password should be the same.",
        }))
        res = false
      }

      return res
  }
  const onLoginClick = () => {
    if (!isValidForm()) return
    setLoading(true)
    if (!passwordConfirm.length || !password.length) {
      setLoading(false)
      return;
    }
    new LoginService().resetPassword({ password: state.password, password_confirmation: state.passwordConfirm, token })
      .then((response) => {
        setLoading(false)
        toast.success('Password changed successfully')
        setTimeout(() => {
            history.push('/login');
        }, 5000);
      })
      .catch((err) => {
        setLoading(false)
        let errors = err.response.data?.message?.errors
        if (!!errors?.length) {
            setApiError(errors.join(', '));
        }else{
          setApiError("The URL is no longer valid");
        }
      });
  };
  
  return (
    <>
      <div className="login">
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
        />
        <div className="password__header">
          KONTORO
        </div>
        <div className="password__title">
          <div className="password__title-text">
            Set New Password
          </div>
        </div>

        <div className="password__container">
          {loading && <LinearProgress className="linear_progress" />}
          <form>
            <p className="password__container__title-text">Please enter your new password.</p>
            <p className={`${(apiError || passwordError || passwordConfirmError) ? 'password__input__error-display' : 'password__input__error-hide'}`}>
              {apiError || passwordError || passwordConfirmError}
            </p>
            <PasswordInput className={`password__input ${passwordError ? "error" : ""}`} id={"password"} placeholder="Password" value={state.password}
              onChange={handleChange} sx={{"& .MuiInputBase-input":{fontSize: "14px", paddingTop: "5px"}}}/>

            <PasswordInput className={`password__input ${passwordConfirmError ? "error" : ""}`} id={"passwordConfirm"} placeholder="Repeat Password" value={state.passwordConfirm}
              onChange={handleChange}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onLoginClick();
                }
              }}
              sx={{"& .MuiInputBase-input":{fontSize: "14px", paddingTop: "5px"}}}
            />

            <Button className="password__button"
              disabled={!state.password.length || !state.passwordConfirm.length}
              onClick={onLoginClick}>
              Save Password
            </Button>
            <div className="password__container__footer" >
              <Link to="/login" className="forgot_link">Return to Login</Link>
            </div>
          </form>
        </div>
      </div>
    </>
  )
};

export default Login;
