import BaseService from "./baseService";

class CredentialsService extends BaseService {
    createCredentials(payload) {
        return this.post('/api/business_accounts/add_credentials', payload);
    }
    getCredentials(params) {
        return this.request({ "url": '/api/business_accounts/get_credentials', params })
    }
    updateCredentials(payload) {
        return this.post('/api/users/update_pass', payload )
    }
}

export default CredentialsService;