import React from 'react'
import { Button as MuiButton } from '@mui/material';
import PropTypes from 'prop-types';
import './index.scss'

const Button = ({ variant = "contained", onClick, className = "btn-primary", children, ...rest }) => {
    return (
        <MuiButton variant={variant} onClick={onClick} className={className}  {...rest} >
            {children}
        </MuiButton>
    )
}

Button.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.string,
    className: PropTypes.string,
};


export default Button
