import React, { useLayoutEffect } from 'react'
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { getValue } from '../../services/storageService';
import './AuthLayout.scss'

const AuthLayout = ({ children }) => {
    const history = useHistory();

    useLayoutEffect(() => {
        if (getValue('token')) history.push('/')
    }, [history])
    
    return (
        <div className="authContainer">
            {children}
        </div>
    )
}

const AuthLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <AuthLayout>
                <Component {...matchProps} />
            </AuthLayout>
        )} />
    )
};


export default AuthLayoutRoute
