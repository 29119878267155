import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Radio,
  Switch,
  Link,
} from "@mui/material";
import styled from "styled-components";
import Header from "../shared/header";
import SubscriptionService from "../../services/http/subscriptionService";
import PlansService from "../../services/http/plansService";
import moment from "moment";
import { useHistory } from "react-router-dom";

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SubscriptionDetailsContainer = styled.div`
  padding: 45px 55px;
  background: transparent;
  width: 414px;
  height: 32px;
  top: 179px;
  left: 30px;
  opacity: 0;
`;

const Div2 = styled.div`
  width: 100%;
  padding: 55px;
  margin: 0;
  background: transparent;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const AccountTypography = styled(Typography)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  text-align: left;
`;

const PlanTypography = styled(Typography)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  margin: 0;
`;

const PlanHeadingTypography = styled(Typography)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  margin: 0;
`;

const SubscriptionText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
`;



const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 899px;
    height: 400px;
    gap: 0px;
    border-radius: 10px;
    opacity: 0px;
    position: absolute;
  }
`;

const CustomDialogTitle = styled(DialogTitle)`
  width: 122px;
  height: 19px;
  top: 308px;
  left: 212px;
  gap: 0px;
  opacity: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  text-align: left;
  white-space: nowrap;
  color: rgba(0, 0, 0, 1);
`;

const PlanTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding:15px 0px;
`;

const PlanRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000000;
  padding: 10px 0;
`;

const PlanDetails = styled.div`
  flex-grow: 1;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DialogActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px; /* Adjust as needed for spacing */
`;

const ErrorMessage = styled(Typography)`
  color: red;
  font-size: 15px;
  margin: -4px 37px;
`;

const StyledButton = styled(Button)`
  background-color: #FFFFFF;
  border: 0.5px solid #233DFF;
  border-radius: 0.5px;
  color: #233DFF;
  text-transform: none;
  height: 41px;
  &:hover {
    background-color: #FFFFFF;
    border: 0.5px solid #233DFF;
    color: #233DFF;
    text-transform: none;
    height: 41px;
  }
`;

const StyledTable = styled(Table)`
  td {
    font-weight: 400;
    font-size: 12px;
  }
`;

const SubscriptionDetails = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [plans, setPlans] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [annualBilling, setAnnualBilling] = useState(false);
  const history = useHistory();
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subscriptionService = new SubscriptionService();
        const plansService = new PlansService();

        const subscriptionResponse =
          await subscriptionService.fetchSubscriptionDetails();
        setData(subscriptionResponse.data);
        setSelectedPlan(
          subscriptionResponse.data.subscriptions?.[0]?.plan.id || ""
        );
        const plansResponse = await subscriptionService.fetchPlans();
        setPlans(plansResponse.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleBillingPortal = async () => {
    try {
      const subscriptionService = new SubscriptionService();
      const response = await subscriptionService.createBillingPortalSession();
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error redirecting to billing portal:", error);
    }
  };

  const handleUpdatePlan = async () => {
    try {
      setSelectedPlan(null)
      setError(null)
      const subscriptionService = new SubscriptionService();
      const plansResponse = await subscriptionService.fetchPlans();
      setPlans(plansResponse.data);
      setShowConfirmDialog(true);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const handleConfirmPlanChange = async (planId) => {
    const currentPlanName = data.subscriptions[0].plan.name;
    const selectedPlan = plans.find((plan) => plan.id === planId);

    if (!selectedPlan) {
      setError("Please select a plan.");
      return;
    }

    if (selectedPlan.name === currentPlanName) {
      setError("You are already on this plan. Please select a different plan.");
      return;
    }

    if (selectedPlan.is_free) {
      setError("You cannot select a free plan. Please select a paid plan.");
      return;
    }

    try {
      const subscriptionService = new SubscriptionService();
      const discount = annualBilling ? "HyXV31Xp" : "";
      const response = await subscriptionService.createCheckoutSession(planId, discount);
      window.location.href = response.data.checkout_url; // Redirect to Stripe checkout
    } catch (error) {
      console.error("Error redirecting to Stripe checkout:", error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "5em" }}>
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  return (
    <>
      <Header />
      <div className="dashboard">
        <div className="dashboard__body" id="dashBody">
          <Div2>
            <HeaderContainer>
              <AccountTypography variant="h4" gutterBottom>
                Account
              </AccountTypography>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleBillingPortal}
              >
                Manage subscription and billing
              </StyledButton>
            </HeaderContainer>
            <SubscriptionText>
              Manage your subscription plan, payment method and download your
              receipts
            </SubscriptionText>

            <StyledTable responsive className="gates__list__body-table wrongUrl">
              <thead className="body__header wrongUrl">
                <tr>
                  <th className="gates__list__body-table-header">Plan</th>
                  <th className="gates__list__body-table-header">Amount</th>
                  <th className="gates__list__body-table-header">Status</th>
                  <th className="gates__list__body-table-header">
                    Period
                  </th>
                  <th className="gates__list__body-table-header">Renewable</th>
                  <th className="gates__list__body-table-header">Actions</th>
                </tr>
              </thead>
              <tbody className="table__body">
                {data?.subscriptions?.length ? (
                  data.subscriptions.map((sub) => (
                    <tr key={sub.id} className="gates__list__body-row">
                      <td className="gates__list__body-table-header">
                        <div className="">{sub.plan.name}</div>
                      </td>
                      <td className="gates__list__body-table-header">
                        <div className="">{sub.status == "trialing" ? "FREE" : `$${sub.plan.amount / 100}`}</div>
                      </td>
                      <td className="gates__list__body-table-header">
                        <div className="">{sub.status}</div>
                      </td>
                      <td className="gates__list__body-table-header">
                        <div className="">
                          {moment(sub.current_period_start).format(
                            "MMM DD, YYYY"
                          )}{" "}
                          to{" "}
                          {moment(sub.current_period_end).format(
                            "MMM DD, YYYY"
                          )}
                        </div>
                      </td>
                      <td className="gates__list__body-table-header">
                        <div className="">
                          {sub.cancel_at_period_end ? "No" : "Yes"}
                        </div>
                      </td>
                      <td className="gates__list__body-table-header">
                        <ActionContainer>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUpdatePlan}
                            sx={{ textTransform: 'none' }}
                          >
                            Update plan
                          </Button>
                        </ActionContainer>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No subscriptions found</td>
                  </tr>
                )}
              </tbody>
            </StyledTable>

            <Box mt={4}>
              <AccountTypography variant="h5" gutterBottom>
                Receipt
              </AccountTypography>
              <SubscriptionText>Download your receipts</SubscriptionText>
              <StyledTable responsive className="gates__list__body-table wrongUrl">
                <thead className="body__header wrongUrl">
                  <tr>
                    <th className="gates__list__body-table-header">
                      Invoice Date
                    </th>
                    <th className="gates__list__body-table-header">
                      Amount Paid
                    </th>
                    <th className="gates__list__body-table-header">Status</th>
                    <th className="gates__list__body-table-header">Description</th>
                    <th className="gates__list__body-table-header"></th>
                  </tr>
                </thead>
                <tbody className="table__body">
                  {data?.invoices?.length ? (
                    data.invoices.map((inv) => (
                      <tr key={inv.id} className="gates__list__body-row">
                        <td className="gates__list__body-table-header">
                          <div className="">
                            {moment(inv.date).format("MMM DD, YYYY")}
                          </div>
                        </td>
                        <td className="gates__list__body-table-header">
                          <div className="">${inv.amount_paid / 100}</div>
                        </td>
                        <td className="gates__list__body-table-header">
                          <div className="">{inv.status}</div>
                        </td>
                        <td className="gates__list__body-table-header">
                          <div className="">
                            {inv.plan_description}
                          </div>
                        </td>
                        <td className="gates__list__body-table-header" style={{ width: '100px' }}>
                          <Link
                            href={inv.hosted_invoice_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: '#233DFF', textDecoration: 'none', textTransform: 'none', lineHeight: '16px' }}
                          >
                            View Receipt
                          </Link>

                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="gates__list__body-row">
                      <td
                        className="gates__list__body-table-header"
                        colSpan="4"
                      >
                        No invoices found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </StyledTable>
            </Box>
          </Div2>
        </div>
      </div>
      <CustomDialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <CustomDialogTitle id="confirm-dialog-title">
          Update your plan
        </CustomDialogTitle>
        <DialogContent>
          <PlanTable>
            {plans?.length ? (
              plans.map((plan) => (
                <PlanRow key={plan.id}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedPlan === plan.id}
                        onChange={() => {
                          setError(null);
                          setSelectedPlan(plan.id);
                        }}
                        value={plan.id}
                        name="plan"
                      />
                    }
                    label={<PlanHeadingTypography variant="h6" sx={{ width: 100, overflow: 'hidden', textOverflow: 'ellipsis' }}>{plan.name}</PlanHeadingTypography>}
                  />
                  <PlanDetails>
                    <PlanTypography variant="body1" color="textSecondary">
                      {plan.is_free ? "FREE" : `$${plan.price_in_cents / 100}/mo`}
                    </PlanTypography>
                  </PlanDetails>

                  <PlanDetails>
                    <PlanTypography variant="body2" color="textSecondary">
                      {plan.monthly_calls} requests per month, {plan.gate_count} gates
                    </PlanTypography>
                  </PlanDetails>
                </PlanRow>
              ))
            ) : (
              <Typography>No plans available</Typography>
            )}
          </PlanTable>

          <FormControlLabel
            control={
              <Switch
                checked={annualBilling}
                onChange={(e) => setAnnualBilling(e.target.checked)}
                color="primary"
              />
            }
            label={<PlanHeadingTypography variant="h6" >Annual billing (Save 15%)</PlanHeadingTypography>}
          />
        </DialogContent>
        {error && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
        <DialogActions>
          <DialogActionsContainer>
            <StyledButton onClick={() => handleConfirmPlanChange(selectedPlan)} color="primary" style={{ width: '138px' }} autoFocus sx={{ fontWeight: 400 }}>
              Choose plan
            </StyledButton>
            <StyledButton onClick={() => setShowConfirmDialog(false)} color="secondary" style={{ width: '138px', color: '#4C4F53', border: '0.5px solid #4C4F53' }} sx={{ fontWeight: 400 }} >
              Cancel
            </StyledButton>
          </DialogActionsContainer>
        </DialogActions>

      </CustomDialog>
    </>
  );
};

export default SubscriptionDetails;
