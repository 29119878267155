import React, { useState } from 'react';
import { Input, Button } from 'reactstrap';
import { LinearProgress } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import './index.scss'
import axios from 'axios';
import { Link } from 'react-router-dom';
import NotificationBar from "../shared/header/notificationBar";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL

const ApiTest = () => {
  const [apiError, setApiError] = useState('');
  const [planUsed, setPlanUsed] = useState(0);
  const initialState = {
    gateId: "",
    agateIdError: false,
    apiSecret: "",
    apiSecretError: false,
    apiKey: "",
    apiKeyError: false,
    identifier: "",
    identifierError: false,
  }
  const [state, setState] = useState(initialState);
  const { gateId, gateIdError, apiSecret, apiSecretError, apiKey, apiKeyError, identifier, identifierError } = state

  const handleChange = (e) => {
    setApiError('')
    setResponse()
    const { id, value } = e.target;
    setState(prevState => ({
      ...prevState,
      gateIdError: false,
      apiSecretError: false,
      apiKeyError: false,
      identifierError: false,
      [id]: value
    }))
  };

  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState()

  const onRegister = () => {
    setLoading(true)
    if (!apiSecret.length) {
      setLoading(false)
      setState(prevState => ({
        ...prevState,
        apiSecretError: true,
      }))
      return setApiError("API Secret is required");
    }
    if (!apiKey.length) {
      setLoading(false)
      setState(prevState => ({
        ...prevState,
        apiKeyError: true,
      }))
      return setApiError("API Key is required");
    }
    if (!gateId.length) {
      setLoading(false)
      setState(prevState => ({
        ...prevState,
        gateIdError: true,
      }))
      return setApiError("Gate Id is required");
    }
    if (!identifier.length) {
      setLoading(false)
      setState(prevState => ({
        ...prevState,
        identifierError: true,
      }))
      return setApiError("Identifier is required");
    }
    axios.get(`${BASE_API_URL}/gates/${gateId}/variation-for-user/${identifier}`, {
      headers: {
        "API-SECRET": apiSecret,
        "API-KEY": apiKey,
      }
    })
      .then((response) => {
        setResponse(response.data)
        setLoading(false)
        setState(initialState)
        if(response?.data?.Message){
          toast.warning(response?.data?.Message);
        }else{
          toast.success('Called Successful');
        }
      }).catch((err) => {
        setLoading(false)
        setApiError("Something went wrong");
        toast.warning("Something went wrong");
      });
  }
  return (
    <>
      <NotificationBar planUsed={planUsed} show />
      <div className="api__test">
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
        />
        <div className={`api__test__header ${planUsed && planUsed > 70 ? 'mt-5' : ''}`}>
          <Link to="/" style={{color: 'inherit', textDecoration: 'none'}}>KONTORO</Link>
        </div>
        <div className="api__test__title">
          <div className="api__test__title-text">
            API
          </div>
        </div>

        <div className="api__test__container">
          {loading && <LinearProgress className="linear_progress" />}
          <p
            className={`${apiError ? 'api__test__input__error-display' : 'api__test__input__error-hide'}`}
          >
            {apiError}
          </p>
          <form>
            <div className='api__test__input__group'>
              <label htmlFor="apiSecret">API Secret <span>*</span> </label>
              <Input type="text" className={`api__test__input ${apiSecretError ? 'error' : ''}`} id={"apiSecret"} placeholder="API Secret  *" value={apiSecret}
                onChange={handleChange} />
            </div>
            <div className='api__test__input__group'>
              <label htmlFor="apiKey">API Key <span>*</span> </label>
              <Input type="text" className={`api__test__input ${apiKeyError ? 'error' : ''}`} id={"apiKey"} placeholder="API Key *" value={apiKey}
                onChange={handleChange} />
            </div>
            <div className='api__test__input__group'>
              <label htmlFor="gateId">Gate Id <span>*</span> </label>
              <Input type="text" className={`api__test__input ${gateIdError ? 'error' : ''}`} id={"gateId"} placeholder="Gate ID *" value={gateId}
                onChange={handleChange} />
            </div>
            <div className='api__test__input__group'>
              <label htmlFor="identifier">Identifier <span>*</span> </label>
              <Input type="email" className={`api__test__input ${identifierError ? 'error' : ''}`} id={"identifier"} placeholder="User Identifier *" value={identifier}
                onChange={handleChange}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    onRegister();
                  }
                }}
              />
            </div>
            <Button className="api__test__button"
              onClick={onRegister}>
              Send
            </Button>
          </form>
          <div className="mt-3">
            {JSON.stringify(response, null, '\t')}
          </div>
        </div>
      </div>
    </>
  )
}

export default ApiTest;
