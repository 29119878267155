import React from 'react'
import Load from "react-loader-spinner";

const Loader = () => {
    return (
        <div className="loader_center" style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width:"100vw",
            position: "absolute"
        }}>
            <Load
                type="Circles"
                color="#b8b4fc"
                height={40}
                width={70}
                timeout={30000}
            />
        </div>
    )
}

export default Loader
