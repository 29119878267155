import BaseService from "./baseService";

class PlansService extends BaseService {
    fetchPlans(params) {
        return this.request({ "url": '/api/business_plans', params })
    }
    planUsed(params) {
        return this.request({ "url": '/api/business_plans/current_plan', params })
    }
    oldPlans() {
      return this.request({ "url": '/api/business_plans/old_plans'})
    }
}

export default PlansService;
