import React, { useState } from 'react'
import { IconButton, InputAdornment, InputBase } from '@mui/material';
import { EyeCloseIcon, EyeOpenIcon } from '../../../assets/icons';

export const PasswordInput = (props) => {
    const [passwordShow, setPasswordShow] = useState(false)
    const togglePassword = () => {
        setPasswordShow(prev => !prev);
    };

    return (
        <InputBase
            hiddenLabel
            variant="standard"
            type={passwordShow ? "text" : "password"}
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle visibility"
                  onClick={(e) => togglePassword()}
                  edge="end"
                  sx={{height: 33, width: 33 }}
                >
                  {passwordShow ? <EyeCloseIcon /> : <EyeOpenIcon />}
                </IconButton>
              </InputAdornment>
            }
            {...props}
        />
    )
}
