import React from 'react';
import { Table } from 'reactstrap';
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { Menu, MenuItem, Fade, IconButton } from '@mui/material';
import './gates.scss';
import { ArchivedIcon, BreakIcon, Circle, DraftIcon, RunningIcon, FilterIcon, LeftArrow, RightArrow } from '../../../assets/icons';
import { GATE_STATES_LABEL } from '../../../constants';
import moment from 'moment';


export const List = ({ item, openCustomer, cols, onEdit, state }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const showIcon = (state) => {
        return (
            <IconButton className="py-0 position-static">
                {
                    (state === 'draft')
                        ?
                        <DraftIcon />
                        :
                        (state === 'running')
                            ?
                            <RunningIcon />
                            :
                            (state === 'paused')
                                ?
                                <BreakIcon />
                                :
                                <ArchivedIcon />
                }
            </IconButton>
        )
    }
    return (
        <tr key={item.id} className={`gates__list__body-row ${state ? state : 'all'}`} >
            {cols.map((col, i) => <td onClick={() => openCustomer(item)} {...col.props} ><div className={`button_container ${col.class? col.class :''}`}>{i === 0 && showIcon(item.state)}<p>{item[col.id] !== null ? item[col.id] : '-'}</p></div></td>)}
            <td className="d-flex justify-content-center" >
                <IconButton
                    id="fade-button"
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className="p-0 position-static"
                >
                    {
                        open ? <Circle /> : <BiDotsVerticalRounded />
                    }
                </IconButton>
                <Menu
                    id="fade-menu"
                    key={item.id}
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    className="gate__menu"
                >
                    <MenuItem onClick={() => { openCustomer(item); handleClose() }}>See details</MenuItem>
                    {
                         item.state_transitions.map(status => (
                            <MenuItem onClick={() => onEdit(item, status)}>
                                {GATE_STATES_LABEL[status]}
                            </MenuItem>
                        ))
                    }
                </Menu>
            </td>
        </tr>
    )
}

const GatesList = ({ gate, openCustomer, setOpen, state, onEdit, ReactPaginate, handlePageClick, paginateGates }) => {
    const [changeSort, setChangeSort] = React.useState(true)
    const cols = [
        {
            id: 'name',
            value: 'Name',
            icon : <FilterIcon />
        },
        {
            id: 'id',
            value: 'Gate ID'
        },
        ...(state === 'draft' || state === 'running' || state === "" || state === 'all' || state === 'paused' || state === "archived") ?
            [{
                id: 'created_at',
                value: 'Creation',
                icon: <FilterIcon />
            }] : []
        ,
        ...(state === 'draft' || state === 'running' || state === "" || state === 'all' || state === 'paused') ?
            [{
                id: 'updated_at',
                value: 'Modified',
                icon: <FilterIcon />
            }] : []
        ,
        // ...(state !== 'draft' && state !== "") ?
        //     [{
        //         id: 'start_at',
        //         value: 'Start Date'
        //     }] : []
        // ,
        // ...(state === 'paused') ?
        //     [{
        //         id: 'last_paused_at',
        //         value: 'Pause Date'
        //     }] : []
        // ,
        ...(state === "archived") ?
            [{
                id: 'end_at',
                value: 'Archived',
                icon: <FilterIcon />
            }] : []
        ,
        ...(state !== 'draft') ?
        [{
            id: 'total_calls',
            value: 'Calls'
        }] : []
    ,
        ...(state === "all" || state === 'paused' || state === "archived" || state === 'draft' || state === 'running') ?
            [{
                id: 'state',
                value: 'Status',
                class: "status_color"

            }] : [],
            
        ];
        
        function sorting(ids){
                setChangeSort(!changeSort)
                if(changeSort){
                    const sorted = gate.length > 0? gate?.sort((a,b)=>a?.[ids].localeCompare(b?.[ids])): [];   
                }else{
                    const sorted = gate.length > 0? gate?.sort((a,b)=>b?.[ids].localeCompare(a?.[ids])): [];   
                }
        }
    
    return (
        <div className="gates__list">
            <div className="gates__list__body">
                <Table responsive className="gates__list__body-table">
                    <thead className='body__header'>
                        <tr>
                            {cols.map((item) => <th key={item.id} className="gates__list__body-table-header">{item.value} <span style={{cursor:'pointer'}} onClick={()=>sorting(item.id)} >{item.icon}</span></th>)}
                            <th className="gates__list__body-table-header"></th>
                        </tr>
                    </thead>
                    <tbody className='table__body'>
                        {
                            (Object.keys(gate).length !== 0)
                                ?
                                gate.length && gate?.map((item) => <List
                                    state={state}
                                    onEdit={onEdit}
                                    item={{
                                        ...item,
                                        created_at: item.created_at && moment(item.created_at).format('MMM DD, YYYY'),
                                        created_atDate: item.created_at && moment(item.created_at).format('MM/DD/YYYY hh:mm:ss'),
                                        start_at: item.start_at && moment(item.start_at).format('MM/DD/YYYY hh:mm:ss'),
                                        last_paused_at: item.last_paused_at && moment(item.last_paused_at).format('MMM DD, YYYY'),
                                        last_paused_atDate: item.last_paused_at && moment(item.last_paused_at).format('MM/DD/YYYY hh:mm:ss'),
                                        updated_at: item.updated_at && moment(item.updated_at).format('MMM DD, YYYY'),
                                        updated_atDate: item.updated_at && moment(item.updated_at).format('MM/DD/YYYY hh:mm:ss'),
                                        end_at: item.end_at && moment(item.end_at).format('MMM DD, YYYY'),
                                        end_atDate: item.end_at && moment(item.end_at).format('MM/DD/YYYY hh:mm:ss'),
                                    }}
                                    openCustomer={openCustomer} cols={cols} />)
                                :
                                <tr onClick={setOpen}><td colSpan={cols.length + 1} className="text-center cursor-pointer" style={{fontWeight: "400" ,fontSize: "12px"}}>There is no gate. <span onClick={setOpen} style={{textDecoration:"underline"}}> Create a gate now.</span></td></tr>
                        }
                    </tbody>
                </Table>
                {
                          (Object.keys(gate).length !== 0) ?
                    <ReactPaginate 
                        previousLabel={<LeftArrow />}
                        nextLabel={<RightArrow />}
                        breakLabel={'...'}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageCount={paginateGates.max_page}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        forcePage={paginateGates.curr_page ? paginateGates.curr_page-1 : null }
                    />
                    : null 
                }
            </div>
        </div>
    )
};

export default GatesList;
