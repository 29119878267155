import {FilterIcon} from "../assets/icons"

export const GATE_STATES = ["draft", "running", "paused", "archived"]
export const GATE_STATES_LABEL = {
    [GATE_STATES[0]]: "Draft", 
    [GATE_STATES[1]]: "Run", 
    [GATE_STATES[2]]: "Pause", 
    [GATE_STATES[3]]: "Archive", 
}


export const WrongUrlCols = [
    {
        id: 'name',
        value: 'Name',
        icon : <FilterIcon />
    },
    {
        id: 'id',
        value: 'Gate ID'
    },
    {
        id: 'creation',
        value: 'Creation',
        icon: <FilterIcon />
    },
    {
        id: 'modified',
        value: 'Modified',
        icon: <FilterIcon />
    },
    {
            id: 'call',
            value: 'Calls',
    }
    ,{
            id: 'state',
            value: 'Status',
    }
];