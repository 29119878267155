import React, { useState } from 'react'
import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep'
import Button from '../button'
import './index.scss'

const INITIAL_PROPS = {
    open: false,
    title: '',
    subtitle: '',
    buttonProps: null,
    callback: null,
}
let setState = null

export const prompt = (title, subtitle, arg3) => {
    const state = { open: true, title, subtitle }
    if (typeof arg3 === 'function') state.callback = arg3
    if (typeof arg3 === 'object' && arg3) state.buttonProps = arg3
    setState(state)

    return { close: () => setState(cloneDeep(INITIAL_PROPS)) }
}

export const closePrompt = () => {
    const confirmContainer  = document.querySelector('.confirmation__dialog__container')
    confirmContainer.style.display= 'none'
    setState(cloneDeep(INITIAL_PROPS))
    return { close: () => setState(cloneDeep(INITIAL_PROPS)) }
}

function Prompt() {
    const [state, _setState] = useState(cloneDeep(INITIAL_PROPS))
    setState = values => _setState({ ...state, ...values })

    const handleClose = () => {
        const confirmContainer  = document.querySelector('.confirmation__dialog__container')
        confirmContainer.style.display= 'none'
        setState(cloneDeep(INITIAL_PROPS))
    } 
    const actionHandler = async intent => {
        const callback = state.callback
        if (callback) await callback(intent)
        handleClose()
    }

    return (
        <>
            <MuiDialog open={state.open} onClose={handleClose} className="confirmation__dialog__container" >
                {state.title && <DialogTitle className={"pb-0"}>
                    <div className="update__gate pb-0">
                        <span className="update__gate__title-text">{state.title}</span>
                    </div>
                </DialogTitle>
                }
                <DialogContent>
                    {state.subtitle &&
                        <Box className="update__gate__body my-3" width="sm">
                            {state.subtitle}
                        </Box>
                    }
                    {
                        state.buttonProps && <Box display="flex" justifyContent="center">
                            {
                                state.buttonProps.map(item => (
                                    <span onClick={() => { if (!item.dontClose) { handleClose() } }}>
                                        {
                                            item.label
                                        }
                                    </span>
                                ))
                            }
                        </Box>
                    }
                </DialogContent>

                {!state.buttonProps && (
                    <DialogActions>
                        <Box display="flex" justifyContent="center">
                            <Box mr={2}>
                                <Button size="small" color="error" variant='outlined' onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Box>
                            <Box>
                                <Button variant='outlined' size="small" onClick={() => actionHandler(true)}>
                                    OK
                                </Button>
                            </Box>
                        </Box>
                    </DialogActions>
                )}
            </MuiDialog>
        </>
    )
}

export default Prompt
