import React from 'react'
import { Link } from 'react-router-dom';
import styled from "styled-components"

const PageNotFound = () => {
  return (
    <Invalidcontainer>  
    <Invalid>
        <div className='heading'>Invalid URL</div>
        <div className="invalid__bottom__container">
            <p>The requested URL was not found on this server.</p>
            <p>Return to <Link to="/">Dashboard.</Link></p>
        </div>
    </Invalid>
    </Invalidcontainer>
  )
}

export default PageNotFound;

const Invalidcontainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const Invalid = styled.div`
    background-color: #ffffff;
    width: 1134px;
    height: 515px;
    margin-top: 68px;
    text-align: center;
    border-radius: 10px;
    .heading{
        font-weight: 700;
        font-size: 20px;
        color: #FE5749;
        margin-top: 40px;
    }
    .invalid__bottom__container{
        font-weight: 400;
        font-size: 13px;
        margin-top: 60px;
        p{
            margin-bottom: 0;
            a{
                color: #4C4F53;
            }
        }
    }
`



