import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Drawer, Menu, MenuItem, IconButton, Fade, Dialog, DialogContent } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { Input } from 'reactstrap';
import './gates.scss';
import './index.css';
import GatesService from '../../../services/http/gatesService';
import Button from '../../shared/button'
import Slider from '@mui/material/Slider';
import VaritionsList from '../../list/varitions';
import { DownArrow, Pencil, UpArrow, Circle, DrawerSettingIcon, DashboardClose, Divider} from '../../../assets/icons';
import { closePrompt, prompt } from '../../shared/prompt';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { HiDotsVertical } from 'react-icons/hi';
import { GATE_STATES, GATE_STATES_LABEL } from '../../../constants';
import { Box } from '@mui/system';
import ApiIntegration from '../../apiIntegration';
import moment from 'moment';
import { DateRangePickerComponent} from '@syncfusion/ej2-react-calendars';

// import CredentialList from '../../credentialList'
import Header from '../../shared/header';
import VariationDetails from '../../variationDetails';
import PopUpApiIntegration from '../../popupApiIntegration';

const MAX = 10;

const KontoroSlider = styled(Slider)({
    color: 'transparent',
    backgroundImage: 'linear-gradient(90deg, hsla(207, 89%, 59%, 0.5), hsla(290, 100%, 57%, 0.5));',
    height: 2.25,
    padding: 0,
    verticalAlign: "middle",
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 10,
        width: 10,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    'body:not(.custom_animation_show) .custom_drawer_animation .MuiPaper-root': {
        right: '-70%',
        transition: '0.5s all',
    },
    'body.custom_animation_show .custom_drawer_animation .MuiPaper-root': {
        right: '0%',
        transition: '0.5s all',
    }
});


const subTitleMsg = {
    [GATE_STATES[0]]: "Fraction of total traffic to include in the gate",
    [GATE_STATES[1]]: "Fraction of total traffic to include in the gate",
    [GATE_STATES[2]]: "Variations can’t be deleted, only traffic can be updated.",
}


const GatesDrawer = ({ open, closeDrawer, onCreateGate, selectedGate: selectGate, editGate, apiData}) => {
    const [selectedGate, setSelectedGate] = useState(selectGate)
    const [sdate,updateS] = useState(moment(selectedGate.start_at).format("LLLL"))
    const [edate,updateE] = useState(String(new Date()))
    const [data,setData] = useState({})
    const [name, setName] = useState(selectedGate ? selectedGate.name : '');
    const history = useHistory();
    const [curState] = useState(selectedGate ? selectedGate.state : '');
    const [loading, setLoading] = useState(false)
    const [loadingCount, setLoadingCount] = useState(false)
    const [isUpdated, setIsUpdated] = useState(false)
    const [apiPopUp, setApiPopUp] = useState(false);
    const [indiError, setIndiError] =useState("")
    const [variationIndiError, setVariationIndiError] = useState("");
    const [checkSave, setCheckSave] = useState(false)
    const [variationAdded, setVariationAdded] = useState(false)
    const [variationDeleted, setVariationDeleted] = useState(false)   

    const handleDate = async(e)=>{
        let s1 = e.target.startDate
        let s2 = e.target.endDate
        updateS(s1)
        updateE(s2)
        s2 = s2.setHours( 23,59,59,0 );
        let d1 = moment(s1).toString()
        let d2 = moment(s2).toString()
        let para =`from=${d1}&to=${d2}`
        fetchPerformInfo(para)
    }

    const stateOptions = editGate ? selectedGate.state_transitions.map(x => ({
        label: x,
        value: x
    })) : null

    // const overlayClickClose = (ele) => {
    //     if (ele.target.closest('.MuiPaper-root') === null) {
    //         closeDrawer();
    //     }
    // }

    const isValidJsonCode = (variations) => {
        let arr = []
        variations.forEach((item, i) => {
            if(!isJson(item.code)){
                arr.push(i)
            }
        })
        return arr
    }
    const isValidForm = () => {
        let error;
        let nameCount = name.length
        if (name === "") {
            setIndiError('Name is required.')
            setError('This is required.','gate')
            return false
        }
        if(name.startsWith(" ")){
            setIndiError('Please remove space.')
            setError('This is required.','gate')
            return false
        }
        // if (name !== "") {
        //     const format = "^[a-zA-Z ]+[a-zA-Z0-9-_ ]+$";
        //     if (!name.match(format)) {
        //         setError("Please enter a valid Gate Name.",'gate')
        //         return false
        //     }
        // }
        if (nameCount < 1 || nameCount > 105) {
            setError('Use between 1 and 105 letters. Gate name must not be used already.','gate');
            return false
        }
        if (error) return false;
        let totalPercent = variations.reduce((acc, x) => {
            if (!x._destroy)
                acc += +x.traffic_percentage;
            return acc;
        }, 0);
        let varLen = variations.filter(i => i._destroy !== true).length
        if (varLen < 1) {
            setError('The minimum of variations is 1 and the maximum is 10.','traffic');
            return false;
        } 

        if (totalPercent > 100) {
            // setError('Traffic allocation must not exceed 100%.','traffic');
            setVariationIndiError("Total traffic allocation must be equal to 100%.")
            return false;
        } else if (totalPercent < 100) {
            setVariationIndiError("Total traffic allocation must be equal to 100%.")
            // setError('Traffic allocation must not be less than 100%.','traffic');
            return false;
        } else {
            hideMsg();
        }
        let codeArr = isValidJsonCode(variations)
        if(codeArr.length){
            setCodeError(codeArr)
            // setError('This is invalid.','traffic')
            return false
        }
        return true;
    }

    const setCodeError = (arr) => {
        let newVariations = [...variations]
        arr.forEach(index => {
            newVariations[index].error = true
            setValues(prevState => ({
                ...prevState,
                variations: newVariations
            }))

        })
    }

    const inputRef = useRef(null)
    const gateRef = useRef(null)
    const SgateRef = useRef(null);
    const msgRef = useRef(null)
    const archiveMsgRef = useRef(null);
    const blankMsgRef = useRef(null);

    const onEdit = (state = selectedGate.state) => {
        if (isUpdated) return
        handleMenuClose()
        if (!isValidForm()) return;
        switch (state) {
            case GATE_STATES[1]:
                prompt(`Are you sure you want to start the experiment?`,
                    <ul style={{paddingLeft: "1.2rem", color: "#4C4F53"}}>
                        <li>{global === 0 ? "Global traffic is 0%, the experience will not be visible to the audience" : `Global traffic is ${global}%`}</li>
                        <li style={{wordBreak:"break-all"}}>{name} will be running after you click on RUN</li>
                        <li>When a gate is running, the variations can not be modified or deleted</li>
                        <li>Only the gate name and traffic can be updated</li>
                    </ul>,
                    [
                        {
                            label: <Button variant="outlined" className="btn btn-success mx-3" style={{background: "#ffffff" , border: "1px solid #D5D9FE", color:"#233dff", textTransform: "capitalize", padding: "2px 36px"}}>Close</Button>,
                        },
                        {
                            label: <Button onClick={() => editAction(state)} className="btn btn-success mx-3" style={{boxShadow:"none", backgroundColor: "#233dff" ,border: "1px solid #233dff !important", color:"#ffffff !important", textTransform: "capitalize", padding: "2px 40px", fontWeight: 600, height:"100%"}}>run</Button>,
                        }
                    ]
                )
                break;
            case GATE_STATES[2]:
                prompt(`Do you want to pause this experiment?`,
                <ul style={{paddingLeft: "1.2rem", color: "#4C4F53"}}>
                    <li style={{wordBreak:"break-all"}}>{name} will not make any more API calls after pausing.</li>
                    <li>When a gate is paused, variations can be added but not deleted.</li>
                </ul>,
                    [
                        {
                            label: <Button className="btn btn-success mx-3" style={{background: "#ffffff" , border: "1px solid #D5D9FE", color:"#233dff", textTransform: "capitalize", padding: "2px 36px", boxShadow:"none"}}>Close</Button>,
                        },
                        {
                            label: <Button variant="outlined" className="btn btn-success mx-3" onClick={() => editAction(state)} style={{boxShadow:"none", backgroundColor: "#233dff" ,border: "1px solid #233dff !important", color:"#ffffff", textTransform: "capitalize", padding: "2px 40px", fontWeight: 600, height:"100%"}}>Pause</Button>,
                        }
                    ]
                )
                break;
            case GATE_STATES[3]:
                prompt(`Are you sure you want to archive this experiment?`,
                    <>
                        <ul style={{paddingLeft: "1.2rem", color: "#4C4F53"}}>
                            <li style={{wordBreak:"break-all"}}>{name} will be archived and cannot be reused when stopped.</li>
                            <li>If you want to pause the experiment, set the Traffic Allocation to zero percent.</li>
                        </ul>
                        <h6 className="text-black my-3" style={{fontWeight: "500", fontSize: "11px"}}>Type ARCHIVE in the box below then click on “Confirm” to stop and archived the experiment.</h6>
                        <input Input className="login__input mt-2 mb-2" ref={inputRef} placeholder="" />
                        <p style={{fontWeight: "400", fontSize: "12px", color: "#FE5749", paddingLeft:"15px"}} ref={archiveMsgRef} className="archive__msg">Please type ARCHIVE.</p>
                        <p style={{fontWeight: "400", fontSize: "12px", color: "#FE5749", paddingLeft:"15px"}} ref={blankMsgRef} className="archiveBlank__msg">This field is required.</p>
                    </>,
                    [
                        {
                            label: <Button className="btn btn-success mx-3" style={{background: "#ffffff" , border: "1px solid #D5D9FE", color:"#233dff", textTransform: "capitalize", padding: "2px 36px", boxShadow:"none"}}>Close</Button>,
                        },
                        {
                            label: <Button variant="outlined" className="btn btn-success mx-3" onClick={() => archiveAction(state)} style={{boxShadow:"none", backgroundColor: "#233dff" ,border: "1px solid #233dff !important", color:"#ffffff", textTransform: "capitalize", padding: "2px 36px", fontWeight: 600, height:"100%"}}>Confirm</Button>,
                            dontClose: true,
                        }
                    ]
                )
                break;

            default:
                editAction(state)
                break;
        }
    }

    const archiveAction = (state = selectedGate.state) => {
        if (inputRef.current.value === "ARCHIVE") {
            closePrompt()
            editAction(state)
        }else if(inputRef.current.value ===""){
            inputRef.current.className += "  error ";
            blankMsgRef.current.classList?.remove('archiveBlank__msg')
            archiveMsgRef.current.classList?.add('archive__msg')
        }
         else {
             inputRef.current.className += "  error ";
             archiveMsgRef.current.classList?.remove('archive__msg')
             blankMsgRef.current.classList?.add('archiveBlank__msg')
        }
    }

    const editAction = (state = selectedGate.state , jsonClick) => {
        if(!jsonClick){
        handleMenuClose()
        setLoading(true)
        if (!isValidForm()) return setLoading(false);
        let varPayload = [...variations]
        // let cnt = 0
        // varPayload = varPayload.map(variation => {
        //     if(variation._destroy === true) return variation
        //     let res =  {
        //         ...variation,
        //         name: cnt === 0 ? 'Control' : `Variation ` + cnt,
        //     }
        //     cnt++
        //     return res
        // })
        const payload = {
            name: name,
            global_traffic: global,
            gate_variations_attributes: varPayload,
            state: state
        }
        new GatesService().updateGate(selectedGate.id, payload).then(response => {
            if (state === GATE_STATES[1] && state !== selectedGate.state || state === GATE_STATES[2] && state !== selectedGate.state || state === GATE_STATES[3] && state !== selectedGate.state) {
                closeDrawer()
                return history.push({
                    pathname: '/dashboard/' + state,
                    state: { msg: `${name} is ${state}.`, type: "success" }
                })
            }
            setSelectedGate(response.data.value)
            setLoading(false)
            setIsUpdated(false)
            if(variationDeleted){
                setIsUpdated(false)
            }
            if (state === GATE_STATES[0] && state !== selectedGate.state || state === GATE_STATES[2] && state !== selectedGate.state || state === GATE_STATES[3] && state !== selectedGate.state) {
                history.push({
                    pathname: '/dashboard/' + state,
                    state: { msg: `${name} has been ${state === selectedGate.state ? 'updated' : state} successfully.`, type: "" }
                })
                closeDrawer()
            }
            return history.push({
                state: {msg: "Changes saved.", type: "success"}
            })
        }).catch(error => {
            console.error(error);
            setError(error.response.data.message,'gate')
            setLoading(false)
        })
        }else{
        if (!isValidForm()) return setLoading(false);
            setLoading(true)
            setCheckSave(false)
            let varPayload = [...variations]
            const payload = {
                name: name,
                global_traffic: global,
                gate_variations_attributes: varPayload,
                state: state
            }
            new GatesService().updateGate(selectedGate.id, payload).then(response => {
                setLoading(false)
                setIsUpdated(false)
                if(curState === GATE_STATES[2] && variationAdded || curState === GATE_STATES[0] && variationAdded || curState === GATE_STATES[0] && variationDeleted || curState === GATE_STATES[2] && variationDeleted){
                    closeDrawer()
                    setVariationAdded(false)
                    setVariationDeleted(false)
                }
                  if(checkSave){
                    closeDrawer()
                    }
                    return history.push({
                        state: {msg: "Changes saved.", type: "success"}
                    })
            }).catch(error => {
                console.error(error);
                setError(error.response.data.message,'gate')
                setLoading(false)
            })
        }
    }

    const [values, setValues] = useState({
        global: selectedGate.global_traffic,
        variations: JSON.parse(JSON.stringify(selectedGate.gate_variations))
    });

    const { global, variations } = values

    useEffect(()=>{
        setValues({
            global: selectedGate.global_traffic,
            variations: JSON.parse(JSON.stringify(selectedGate.gate_variations))
        })
    },[selectedGate])

    const handleChange = (e) => {
        const { name, value } = e.target;
        hideMsg()
        setValues(prevState => ({
            ...prevState,
            [name]: value
        }))
        setIsUpdated(true)
    };

    const handleVariationChange = (e, key = 'traffic_percentage') => {
        setVariationIndiError("")
        //console.log(e.target)
        const { name, value } = e.target;
        let newVariations = [...variations]
        newVariations[name][key] = value
        setValues(prevState => ({
            ...prevState,
            variations: newVariations
        }))
        setIsUpdated(true)
    };

    const handleCodeChange = (name, value) => {
        let newVariations = [...variations]        
        try{
            newVariations[name].code = JSON.parse(value)
        }catch (e){
            newVariations[name].code = value
        }
        newVariations[name].error = false
        setValues(prevState => ({
            ...prevState,
            variations: newVariations
        }))
        setIsUpdated(true)
    };
    const isJson = (str) => {
        if(!str) return false
        try{
            if(typeof str === "object"){
                JSON.parse(JSON.stringify(str))
            }else{
                JSON.parse(str);
            }
        }catch (e){
            return false;
        }
      
       return true;
    }

    const drawerTabsOnClick = (ele) => {
        document.querySelector('.drawer__container .drawer__body').classList.remove('drawer__tabs_config_active');
        // document.querySelector('.drawer__container .drawer__body').classList.remove('drawer__tabs_apiIntegration_active');
        document.querySelector('.drawer__container .drawer__body').classList.remove('drawer__tabs_performance_active');
        document.querySelector('.drawer__container .drawer__body .drawer__tab_active').classList.remove('drawer__tab_active');
        
        let element = document.querySelector(".STILE")
        if(element){
            element.classList.remove("STILE")
        }
      
       
        if(ele.target.classList.length === 1){
            ele.target.parentNode.classList.add("STILE")
        }
        else{
            
            ele.target.classList.add("STILE")
        }
        
        
        if (ele.currentTarget.classList.contains('drawer__tabs_config') === true) {
            document.querySelector('.drawer__container .drawer__body').classList.add(ele.currentTarget.getAttribute('data-tab-active'));
            ele.currentTarget.classList.add('drawer__tab_active');
        // } else if (ele.currentTarget.classList.contains('drawer__tabs_apiIntegration') === true) {
        //     setApiPopUp(true)
        //     document.querySelector('.drawer__container .drawer__body').classList.add(ele.currentTarget.getAttribute('data-tab-active'));
        //     ele.currentTarget.classList.add('drawer__tab_active');
        } else if (ele.currentTarget.classList.contains('drawer__tabs_performance') === true) {
            document.querySelector('.drawer__container .drawer__body').classList.add(ele.currentTarget.getAttribute('data-tab-active'));
            ele.currentTarget.classList.add('drawer__tab_active');
        }
    }

    const handleDelete = (variation, id, cnt) => {
        prompt(``,
        <div className="delete_container">
            <span className='delete__variation'>Are you sure you want to delete {variation.name} ? </span>
            </div>,
            [
                {
                    label: <Button variant="outlined" className="btn btn-success mx-3" style={{background: "#ffffff" ,color:"", border: "1px solid #D5D9FE", color:"#233dff", textTransform: "capitalize", padding: "2px 36px"}} onClick={() => deleteAction(variation, id, cnt)}>Delete</Button>,
                },
                {
                    label: <Button className="btn btn-success mx-3" style={{boxShadow:"none", backgroundColor: "#233dff" ,border: "1px solid #233dff !important", color:"#ffffff !important", textTransform: "capitalize", padding: "2px 36px", fontWeight: 600}}>Close</Button>,
                }
            ]
        )
    }
   

    const deleteAction = (variation, id, cnt) => {
        const variationItems = [...variations];
        if (!variation.id) {
            variationItems.splice(id, 1)
        } else {
            variationItems[id] = { ...variationItems[id], _destroy: true }
        }
        setValues(prevState => ({
            ...prevState,
            variations: variationItems
        }))
        setVariationDeleted(true)
        return history.push({
            state: {msg: `${variation.name} has been deleted successfully.` , type: "success"}
        })
        // setMessage(`${variation.name} was deleted with success.`)
    }

    // const StyledMenu = styled((props) => (
    //     <Menu
    //         elevation={0}
    //         anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //         }}
    //         transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'center',
    //         }}
    //         {...props}
    //     />
    // ))(({ theme }) => ({
    //     '& >.MuiPaper-root': {
    //         marginTop: 0,
    //         minWidth: 90,
    //         borderRadius: `0px 0px 4px 4px`,
    //         backgroundColor: 'hsla(0, 0%, 96%, 1)',
    //         color:
    //             theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    //         boxShadow:
    //             'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    //         '& .MuiMenu-list': {
    //             padding: '0',
    //         },
    //         '& .MuiMenuItem-root': {
    //             textTransform: 'uppercase',
    //             justifyContent: 'center',
    //             fontSize: '11px',
    //             lineHeight: '16px',
    //             letterSpacing: '0.25px',
    //             borderBottom: '1px solid hsla(0, 0%, 92%, 1)',
    //             padding: '2px',
    //             '& .innerList': {
    //                 width: '100%',
    //                 textAlign: 'center',
    //             },
    //             '& .disabled': {
    //                 cursor: 'not-allowed',
    //             },
    //             '&:hover': {
    //                 backgroundColor: 'transparent',
    //                 '& .innerList': {
    //                     backgroundColor: 'hsla(237, 69%, 40%, 0.06)',
    //                 },
    //                 '& .disabled': {
    //                     cursor: 'not-allowed',
    //                     backgroundColor: 'transparent',
    //                 },
    //             },
    //             '& .MuiSvgIcon-root': {
    //                 fontSize: 18,
    //                 color: theme.palette.text.secondary,
    //                 marginRight: theme.spacing(1.5),
    //             },
    //             '&:active': {
    //                 backgroundColor: alpha(
    //                     theme.palette.primary.main,
    //                     theme.palette.action.selectedOpacity,
    //                 ),
    //             },
    //         },
    //     },
    // }));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openCircle = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // const menuOpen = Boolean(anchorEl);
    // const handleMenuClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const addVariation = _ => {
        const additionNum = variations.filter(i=>i._destroy !== true).length === 1 ? 1 : Number(variations[variations.length - 1].name.split(" ")[1] || 1)+1;
        setValues(prevState => ({
            ...prevState,
            variations: [...prevState.variations, {
                name: `Variation ` + additionNum,
                traffic_percentage: 0,
                code: {},
                _newVariation: true,

            }]
        }))
        setVariationAdded(true)
    }
    const intialMsg = {
        msg: "",
        isError: false,
        gate: false,
        traffic: false
    }
    const [msg, setMsg] = useState(intialMsg)
    const setMessage = (msg) => {
        setMsg(prev => ({
            ...prev,
            msg: msg,
            isError: false,
            gate: false,
            traffic: false
        }))
        setTimeout(() => {
            hideMsg()
        }, 10000);
    }
    const setError = (err, type) => {
        setMsg(prev => ({
            ...prev,
            msg: err,
            isError: true,
            [type]: true,
        }))
        setTimeout(() => {
            hideMsg()
        }, 10000);
    }

    const hideMsg = () => {
        setMsg(intialMsg)
        
    }

    const inputError = () =>{
        setIndiError("")
    }
    
    useEffect(() => {
        if(msgRef.current){
            msgRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
    }, [msg])

    const splitEvenlty = (showMsg = false) => {
        let newVariations = []
        let firstElm = variations.findIndex(variation => variation._destroy !== true)
        let totLength = variations.filter(variation => variation._destroy !== true).length
        let traffic = Math.floor(100 / totLength)
        variations.map(item => (
            newVariations.push({ ...item, traffic_percentage: traffic })
        ))
        if(firstElm !== -1){
            newVariations[firstElm].traffic_percentage = newVariations[firstElm].traffic_percentage + (100 - (totLength * traffic))
        }
        setValues(prevState => ({
            ...prevState,
            variations: newVariations
        }))
        showMsg && setMessage("The traffic has been distributed with success.")
        setIsUpdated(true)
    }

    const firstUpdate = useRef(true);
    
    useEffect(()=>{
        if(selectedGate.start_at !== null){ 
            let d1 = moment(sdate).toString()
            let d2 = moment(edate).toString()
            let para =`from=${d1}&to=${d2}`
            if(selectedGate){
                fetchPerformInfo(para)
            }
        }
        // eslint-disable-next-line
    },[])

    const calRef = useRef(null)
    const showCal = () => {
        if(calRef.current){
            calRef.current.show()
            let d1 = moment(selectedGate.start_at).format('l').split("/")
            let d2 = moment(String(new Date())).format('l').split("/")
            // console.log(d1[0])
            // console.log(d2[0])
            if(d1[0] === d2[0]){
                calRef.current.leftCalendar.children[0].children[2].dispatchEvent(new Event('mousedown'))
            }
        }
        const cancel = document.querySelector(".e-cancel");
        cancel.innerHTML = "Close"
    }
    
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        splitEvenlty()
        // eslint-disable-next-line
    }, [variations.length, variations.filter(variation => variation._destroy !== true).length])

    const isAddAllowed = () => {
        return variations.filter(variation => variation._destroy !== true).length < MAX
    }
    const fetchPerformInfo =(para)=>{
        setLoadingCount(true)
        new GatesService().fetchGate(selectedGate.id,para).then(function(response){
            let objects =[]
            response.data.value.forEach(data=>{
                let object = {};
                object['name'] = data.name;
                object['id'] = data.id;
                object['unique_visitors'] = data.unique_visitors;
                object['total_calls'] = data.total_calls;
                objects.push(object);
            })
            setLoadingCount(false)
            setLoading(false)
            setData(objects)
        })
    }

    const openApiPopUp = () =>{
        setApiPopUp(true)
    }
    
    const apiPopupClose = () =>{
        setApiPopUp(false);
    }

    const jsonCopied = ()=>{
        return history.push({
            state: {msg: "JSON script is successfully copied. ", type: "success"}
        })
    }

    let cnt = 0

    return (
        <>
            <Drawer transitionDuration={{ enter: 500, exit: 1000 }} className="drawer__container custom_drawer_animation" anchor='right' open={open} hideBackdrop={true}>
                {
                    loading &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', backgroundColor: '#00000015', height: '100vh', width: '100%', zIndex: 1 }}>
                        <CircularProgress color="inherit" />
                    </Box>
                }
                <Header isUpdated={isUpdated} setCheckSave={setCheckSave} closeDrawer={closeDrawer} />
                {/* <div  className="drawer__header d-flex align-items-center justify-content-between">
                    {
                        curState !== GATE_STATES[3] &&
                        <>
                            <Button
                                id="actions-button"
                                endIcon={
                                    menuOpen ?
                                        <UpArrow />
                                        :
                                        <DownArrow />
                                }
                                aria-controls="actions-menu"
                                aria-haspopup="true"
                                aria-expanded={menuOpen ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleMenuClick}
                                onMouseOver={handleMenuClick}
                                style={{ width: 90, fontSize: "11px", borderRadius: `${menuOpen ? '4px 4px 0px 0px' : '4px'}` }}
                            >
                                actions
                            </Button>
                            <StyledMenu 
                                id="actions-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'actions-button',
                                    onMouseLeave: handleMenuClose
                                }}
                                anchorEl={anchorEl}
                                open={menuOpen}
                                onClose={handleMenuClose}
                            >
                                {(curState === GATE_STATES[0] || curState === GATE_STATES[2]) && <MenuItem onClick={() => { onEdit(GATE_STATES[1]); }} disableRipple>
                                    <div className={`innerList ${isUpdated ? 'disabled' : ''}`}>RUN LIVE</div>

                                </MenuItem>}
                                <MenuItem onClick={() => editAction()} disableRipple>
                                    <div className={`innerList ${!isUpdated ? 'disabled' : ''}`}>UPDATE</div>
                                </MenuItem>
                                {
                                    stateOptions.map(item => item.label !== GATE_STATES[1] && (
                                        <MenuItem onClick={() => onEdit(item.label)} disableRipple>
                                            <div className={`innerList ${isUpdated ? 'disabled' : ''}`}>{GATE_STATES_LABEL[item.label]}</div>
                                        </MenuItem>
                                    ))
                                }
                            </StyledMenu>
                        </>
                    }
                </div> */}
                {indiError ? null : 
                <>
                    {
                        msg.msg
                        &&
                         
                            <div ref={msgRef} className={`drawer__body__success ${msg.isError && "error"}`}>
                            <div className="header__msg">
                                {msg.isError && <div className="success_icon_msg">Error</div>}
                                {!msg.isError && <div className="success_icon_msg">Success</div>}
                                <div className="success-close" onClick={hideMsg}>close</div>
                                </div>
                                <div className="success-msg">{msg.msg} </div>
                            </div>
                    }
                </>
                }

                <div className="drawer__body drawer__tabs_config_active">
                    <div style={{backgroundColor:'#ffffff'}}>
                    <div style={{height:"50px",margin:"0"}} className="drawer__tabs">
                        <div className="tabs__container">
                            <span  className="drawer__tabsOptions drawer__tabs_config drawer__tab_active tile STILE" data-tab-active="drawer__tabs_config_active" onClick={(ele) => drawerTabsOnClick(ele)}><span class="tab">Details</span></span>
                            <span  className="drawer__tabsOptions drawer__tabs_performance tile" data-tab-active="drawer__tabs_performance_active"  onClick={(ele) => drawerTabsOnClick(ele)}><span class="tab">Performance</span></span>
                        </div>
                        <div  className="drawer__header-close" onClick={isUpdated ? ()=> setCheckSave(true) : ()=>closeDrawer()}>
                            <DashboardClose /><p>Go back to dashboard</p>
                        </div>
                    </div>
                    </div>
                    <div style={{background:"transparent",width:"100%",margin:"0",padding: "0px 72px 0px 75px",background: "#fafafa"}}>
                    <form>
                        <div className="drawer__tabs_content drawer__tabs_configContent">
                    <div className="sticky__header">
                        <div className="d-flex cta__header">
                            <div className="action_cta">
                            {
                            (curState === GATE_STATES[3])
                                ?
                                <div className="d-flex my-3">
                                    <div className="drawer__body__data">
                                        <div className="drawer__body__data__value archived" style={{fontSize: "24px" ,fontWeight: "500", marginLeft: "0"}}>{name}</div>
                                    </div>
                                </div>
                                :
                                <>
                                <div className={`drawer__body-entity w-100 ${msg.gate ? 'error' : ''} `}>
                                    <Input innerRef={gateRef} minLength={1} maxLength={105}  className={`drawer__body-input input-width-${name.length >= 50  ? "115" : name.length+1 }`} value={name} type="text" onChange={(e) => {
                                        hideMsg()
                                        inputError();
                                        setName(e.target.value);
                                        setIsUpdated(true)
                                    }} />
                                    <Pencil onClick={() => gateRef.current.focus()} style={{ cursor: "pointer" }} />
                                </div>
                                <p className='indi__error'>{indiError}</p>
                                </>

                            }
                            </div>
                            {
                                (curState === GATE_STATES[3])?
                                    null :
                            <div  className="cta__button">
                                <Button className='btn-outline-primary save__btn' onClick={() => editAction()} disabled={!isUpdated}>Save</Button>
                                <Button className='btn-outline-primary dynamic__btn' onClick={() => onEdit(stateOptions[0]?.label)} disabled={isUpdated ? 'disabled' : null}>{GATE_STATES_LABEL[stateOptions[0]?.label]}</Button>
                                {stateOptions[1]?  
                                <>
                                    <IconButton
                                    id="fade-button"
                                    aria-controls="fade-menu"
                                    aria-haspopup="true"
                                    aria-expanded={openCircle ? 'true' : undefined}
                                    onClick={handleClick}
                                    className="p-0 setting__btn"
                                    >
                                    {
                                        openCircle ? <Circle /> : <DrawerSettingIcon />
                                    }
                                </IconButton>
                                <Menu
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={openCircle}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                                className="gate__menu"
                                >
                                    <MenuItem onClick={() => onEdit(stateOptions[1]?.label)}>{GATE_STATES_LABEL[stateOptions[1]?.label]}</MenuItem>
                                </Menu>
                                </>
                                 : null
                                }

                            </div>
                            }
                            <Dialog open={checkSave} onClose={()=>setCheckSave(false)}  className="confirmSave-dialog confirmSave_popup" >
                                <DialogContent className="confirm__save__content">
                                <Box width="sm">
                                    <div className="confirm__save">
                                        <p>Any changes will be lost if you don't click "Save".</p>
                                        <div className="confirmSave__cta">
                                            <button type="button" class="btn btn-outline-primary" onClick={()=>closeDrawer()}>Do not apply change</button>
                                            <Button className='btn-outline-primary save__btn' onClick={(jsonClick) => editAction(selectedGate.state, jsonClick)}>Save</Button>
                                        </div>
                                    </div>
                                    </Box>
                                </DialogContent>
                            </Dialog>
                        </div>
                        <div className="d-flex date__data">
                            <div className="d-flex left">
                                <p>{selectedGate.state}</p>
                                <Divider />
                                <div className="drawer__body__data gate_id">
                                    <div className="drawer__body__data__label">Gate ID:</div>
                                    <div className="drawer__body__data__value">{selectedGate.id}</div>
                                </div>
                                <Divider />
                                <div className="drawer__body__data api_integration">
                                        <span  className="drawer__tabsOptions drawer__tabs_apiIntegration" data-tab-active="drawer__tabs_apiIntegration_active" onClick={openApiPopUp}><span class="tab">API Integration</span></span>
                                </div>
                            </div>
                            <div className="d-flex">
                                    <div className="drawer__body__data">
                                        <div className="drawer__body__data__label">Created on:</div>
                                        <div className="drawer__body__data__value">{moment(selectedGate.created_atDate).format('MM/DD/YYYY hh:mm:ss')}</div>
                                    </div>
                                    {
                                        curState === GATE_STATES[1] && selectedGate.start_at && <div className="drawer__body__data">
                                            <div className="drawer__body__data__label">Started on:</div>
                                            <div className="drawer__body__data__value">{selectedGate.start_at}</div>
                                        </div>
                                    }
                                    {
                                        curState !== GATE_STATES[0] && curState !== GATE_STATES[1] && selectedGate.start_at && <div className="drawer__body__data">
                                            <div className="drawer__body__data__label">Started on:</div>
                                            <div className="drawer__body__data__value">{selectedGate.start_at}</div>
                                        </div>
                                    }

                                    {
                                        curState === GATE_STATES[2] && selectedGate.last_paused_at && <div className="drawer__body__data">
                                            <div className="drawer__body__data__label">Paused on:</div>
                                            <div className="drawer__body__data__value">{selectedGate.last_paused_atDate}</div>
                                        </div>
                                    }
                                    {
                                        curState !== GATE_STATES[3]  &&  selectedGate.updated_at && <div className="drawer__body__data">
                                        <div className="drawer__body__data__label">Last modified on:</div>
                                        <div className="drawer__body__data__value">{selectedGate.updated_atDate}</div>
                                    </div>
                                    }
                                    {
                                        curState === GATE_STATES[3] && selectedGate.end_at && <div className="drawer__body__data">
                                            <div className="drawer__body__data__label">Archived on:</div>
                                            <div className="drawer__body__data__value">{selectedGate.end_atDate}</div>
                                        </div>
                                    }
                               </div>
                        </div>
                        <div style={{height:"29px", background:"#fafafa"}}></div>
                        </div>
                        <div className="drawer__body-container">
                        <div className="drawer__body-title">
                            Gate traffic allocation
                        </div>
                        <div className="drawer__body-subtitle">
                            {subTitleMsg[curState]}
                        </div>

                        <div className="drawer__body__variations">
                            <div className="slider__container">

                            <div className="drawer__body__entity mt-0">
                                <div className="drawer__body__entity__form">
                                    <div className="drawer__body__entity__form__control">
                                        <KontoroSlider
                                            aria-label="global traffic"
                                            name="global"
                                            value={values.global}
                                            onChange={handleChange}
                                            disabled={curState === GATE_STATES[3]}
                                        />
                                    </div>
                                    <div className="drawer__body__entity__form__label">

                                        {values.global} %
                                    </div>
                                </div>
                            </div>
                            {
                                curState !== GATE_STATES[3] &&
                                <>
                                    <Button
                                        id="allocation-button"
                                        variant="outlined"
                                        disableElevation
                                        onClick={() => splitEvenlty(true)}
                                        className={`h-sm drawer__body__variations__allocation-btn }`}
                                        >
                                        Equally distribute percentage weight of each variant 
                                    </Button>
                                    {/* <Button  id="allocation-button" disableElevation  style={{fontSize:"11.7px",color:'#A4A6AB',paddingLeft:"20px",paddingRight:"20px",letterSpacing:"0.3px",border:"1px solid #A4A6AB",background:"transparent"}} onClick={() => splitEvenlty(true)}> EQUALLY DISTRIBUTE PERCENTAGE WEIGHT OF EACH VARIANT</Button> */}
                                </>
                            }
                            </div>
                           <div className="drawer__body__entity-container">
                            <div className="drawer__body-title">
                                Variations
                            </div>
                            <div className="drawer__body-subtitle">
                                Create variations you want to include in the gate
                            </div>
                            <p className='indi__error variation'>{variationIndiError}</p>
                            <div className="drawer_body__entity__container">
                            {
                                variations.map((variation, index) => {
                                    
                                    if (variation._destroy) return null
                                    cnt++
                                    return (
                                        <VariationDetails variationIndiError={variationIndiError} isUpdated={isUpdated} indiError={indiError} jsonCopied={jsonCopied} setLoading={setLoading} isValidForm={isValidForm} msg={msg} index={index} variation={variation} handleVariationChange={handleVariationChange} curState={curState} handleDelete={handleDelete} cnt={cnt} editAction={editAction} handleCodeChange={handleCodeChange} />
                                    )
                                })
                            }
                            </div>
                            </div>
                            {
                                (curState === GATE_STATES[0] || curState === GATE_STATES[2]) && isAddAllowed() && <Button className="drawer__body-variation_btn mt-3" onClick={addVariation}>Add variation</Button>
                            }
                        </div>
                        </div>
                        </div>
                        <div className="drawer__tabs_content drawer__tabs_apiInteContent" >
                            <PopUpApiIntegration apiPopUp={apiPopUp} setApiPopUp={setApiPopUp} apiPopupClose={apiPopupClose} gateId={selectedGate.id} apiKey={apiData?.api_key} secret={apiData?.secret} />
                        </div>
                        <div className="drawer__tabs_content drawer__tabs_performanceContent">
                        <div className="sticky__header">
                        <div className="d-flex cta__header">
                            <div className="action_cta">
                            {
                            (curState === GATE_STATES[3])
                                ?
                                <div className="d-flex my-3">
                                    <div className="drawer__body__data">
                                        {/* <div className="drawer__body__data__label">test name</div> */}
                                        <div className="drawer__body__data__value archived" style={{fontSize: "24px" ,fontWeight: "500", marginLeft: "0"}}>{name}</div>
                                    </div>
                                </div>
                                :
                                <>
                                <div className={`drawer__body-entity w-100 ${msg.gate ? 'error' : ''} `}>
                                    <Input innerRef={SgateRef} className={`drawer__body-input input-width-${name.length+1}`} value={name} type="text" onChange={(e) => {
                                        hideMsg()
                                        inputError()
                                        setName(e.target.value);
                                        setIsUpdated(true)
                                    }} />
                                    <Pencil onClick={() => SgateRef.current.focus()} style={{ cursor: "pointer" }} />
                                </div>
                                 <p className='indi__error'>{indiError}</p>
                                 </>
                        }
                            </div>
                            {
                                (curState === GATE_STATES[3])?
                                    null :
                            <div  className="cta__button">
                                <Button className='btn-outline-primary save__btn' onClick={() => editAction()} disabled={!isUpdated}>Save</Button>
                                <Button className='btn-outline-primary dynamic__btn' onClick={() => onEdit(stateOptions[0]?.label)} disabled={isUpdated ? 'disabled' : null}>{GATE_STATES_LABEL[stateOptions[0]?.label]}</Button>
                                {stateOptions[1]?  
                                <>
                                    <IconButton
                                    id="fade-button"
                                    aria-controls="fade-menu"
                                    aria-haspopup="true"
                                    aria-expanded={openCircle ? 'true' : undefined}
                                    onClick={handleClick}
                                    className="p-0 setting__btn"
                                    >
                                    {
                                        openCircle ? <Circle className="circle" /> : <HiDotsVertical className="dots" />
                                    }
                                </IconButton>
                                <Menu
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={openCircle}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                                className="gate__menu"
                                >
                                    <MenuItem onClick={() => onEdit(stateOptions[1]?.label)}>{GATE_STATES_LABEL[stateOptions[1]?.label]}</MenuItem>
                                </Menu>
                                </>
                                 : null
                                }

                            </div>
                            }
                        </div>
                        <div className="d-flex date__data">
                            <div className="d-flex left">
                            <p>{selectedGate.state}</p>
                            <Divider />
                        <div className="drawer__body__data gate_id">
                                <div className="drawer__body__data__label">Gate ID:</div>
                                <div className="drawer__body__data__value">{selectedGate.id}</div>
                            </div>
                            <Divider />
                            <div className="drawer__body__data api_integration">
                                   <span  className="drawer__tabsOptions drawer__tabs_apiIntegration" data-tab-active="drawer__tabs_apiIntegration_active" onClick={openApiPopUp}><span class="tab">API Integration</span></span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="drawer__body__data">
                                <div className="drawer__body__data__label">Created on:</div>
                                <div className="drawer__body__data__value">{moment(selectedGate.created_atDate).format('MM/DD/YYYY hh:mm:ss')}</div>
                            </div>
                            {
                                curState === GATE_STATES[1] && selectedGate.start_at && <div className="drawer__body__data">
                                    <div className="drawer__body__data__label">Started on:</div>
                                    <div className="drawer__body__data__value">{selectedGate.start_at}</div>
                                </div>
                            }
                            {
                                curState !== GATE_STATES[0] && curState !== GATE_STATES[1] && selectedGate.start_at && <div className="drawer__body__data">
                                    <div className="drawer__body__data__label">Started on:</div>
                                    <div className="drawer__body__data__value">{selectedGate.start_at}</div>
                                </div>
                            }

                            {
                                curState === GATE_STATES[2] && selectedGate.last_paused_at && <div className="drawer__body__data">
                                    <div className="drawer__body__data__label">Paused on:</div>
                                    <div className="drawer__body__data__value">{selectedGate.last_paused_atDate}</div>
                                </div>
                            }
                             {
                                curState !== GATE_STATES[3]  &&  selectedGate.updated_at && <div className="drawer__body__data">
                                    <div className="drawer__body__data__label">Last modified on:</div>
                                    <div className="drawer__body__data__value">{selectedGate.updated_atDate}</div>
                                </div>
                            }
                            {
                                curState === GATE_STATES[3] && selectedGate.end_at && <div className="drawer__body__data">
                                    <div className="drawer__body__data__label">Archived on:</div>
                                    <div className="drawer__body__data__value">{selectedGate.end_atDate}</div>
                                </div>
                            }
                            </div>
                        </div>
                        <div style={{height:"36px", background:"#fafafa"}}></div>
                        </div>
                            <div className="performance__footer__container">
                            <div className="drawer__body-title">
                            Total Calls
                            </div>
                                {/* {curState === GATE_STATES[0] || curState === GATE_STATES[1] && <p className="drawer__body-subtitle mb-0">When the gate is running, an api call is sent to the server. </p>} */}
                               <p className="drawer__body-subtitle mb-0">When the gate is running, calls made to the API are displayed here.</p>
                            <div  className="drawer__footer">
                                {
                                    selectedGate.start_at && <div className="pt-3" style={{ background:"transparent",  minWidth:"190px", width:"max-content" }} onClick={showCal}>
                                        <DateRangePickerComponent isCustomWindow={true} ref={calRef} strictMode={true} showClearButton={false} allowEdit={false} min={selectedGate.start_at} max={String(new Date())}  startDate={sdate} endDate={edate} onChange={handleDate}  />
                                    </div>
                                }
                                {
                                    loadingCount ?
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', position: 'absolute', height: '50vh', width: '95%', zIndex: 1 }}>
                                        <CircularProgress color="inherit" />
                                    </Box>
                                    :
                                    <VaritionsList data={data} />
                                }
                            </div>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </Drawer>
        </>
    )
}
export default GatesDrawer;


