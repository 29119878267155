import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Button, Input } from "reactstrap";
import LoginService from '../../services/http/loginService';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { setValue } from '../../services/storageService';
import './login.scss';
import { GoogleLogin } from 'react-google-login';
import { toast, ToastContainer } from 'react-toastify';
import { FcGoogle } from "react-icons/fc";
import { Box, Checkbox, FormControlLabel, LinearProgress, Dialog, DialogTitle, DialogContent } from '@mui/material';
import UncheckCircleIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PasswordInput } from '../shared/input';
import logo from "../../assets/images/Logo.png";
import BusinessAccountSignup from '../BusinessAccountSignup'; 

const Login = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false)
  const [succesOpen, setSuccesOpen] = useState(false)
  const [apiError, setApiError] = useState('');
  const [indiEmailError, setIndiEmailError] = useState('')
  const [indiPasswordError, setIndiPasswordError] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('');
  const [state, setState] = useState({
    email: "",
    emailError: false,
    password: "",
    passwordError: false,
    businessAccount: "",
    businessAccountError: false,
    // rememberMe: false
  });
  const location = useLocation();
  const [redirect, setRedirect] = useState(false)
  const [businessAccounts, setBusinessAccounts] = useState([])
  const [step, setStep] = useState(1)

  const validateEmail = (value) => {
    const mailformat = "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$";
    if (value.match(mailformat)) {
      return true;
    }
    else {
      return false;
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setApiError('')
    setIndiPasswordError('')
    setIndiEmailError('')
    setErrorMsg('')
    setState(prevState => ({
      ...prevState,
      emailError: false,
      passwordError: false,
      businessAccountError: false,
      [id]: value
    }))
  };

  const handleBusinessAccount = (e) => {
    const { id, value } = e.target;
    setApiError('')
    setErrorMsg('')
    setState(prevState => ({
      ...prevState,
      emailError: false,
      passwordError: false,
      businessAccountError: false,
      [id]: value
    }))
  };

  const onLoginClick = () => {
    const { email, password, businessAccount } = state;
    setLoading(true)

    if (!email.length && !password.length) {
      setLoading(false)
      setApiError('Both field are required.')
      setState(prevState => ({
        ...prevState,
        emailError: true,
        passwordError: true,
      }))
      return;
    }

    if (!email.length) {
      setLoading(false)
      setApiError('Email is required')
      setState(prevState => ({
        ...prevState,
        emailError: true,
      }))
      return;
    } 
    // else if (!validateEmail(email)) {
    //   setLoading(false)
    //   setApiError('The email you entered is incorrect.')
    //   setState(prevState => ({
    //     ...prevState,
    //     emailError: true,
    //   }))
    //   return;
    // }

    if (!businessAccount.length) {
      setLoading(false)
      setApiError('This field is required')
      setState(prevState => ({
        ...prevState,
        businessAccountError: true,
      }))
      return;
    } 
  
    if(!validateEmail(email) && !password.length > 3 ){
      setLoading(false)
      setIndiEmailError("The email you entered is incorrect.");
      setIndiPasswordError("The password you entered is incorrect.")
      setState(prevState => ({
        ...prevState,
        emailError: true,
        passwordError: true,
      }))
      return;
    }

    if(!validateEmail(email) && !password.length){
      setLoading(false)
     setIndiEmailError('The email you entered is incorrect.')
      setIndiPasswordError('This field is required.')
      setState(prevState => ({
        ...prevState,
        emailError: true,
        passwordError: true,
      }))
      return;
    }

    if (!password.length) {
      setLoading(false)
      setIndiPasswordError('This field is required')
      setState(prevState => ({
        ...prevState,
        passwordError: true,
      }))
      return;
    }

    new LoginService().login({ email: state.email, password: state.password, business_account: state.businessAccount })
      .then((response) => {
        setValue('token', response.data.value.token);
        setValue('email', response.data.value.email);
        setValue('bussinessID', response.data.value.business_account_id);
        setValue('billing_type', response.data.value.billing_type);
        history.push({
          pathname: '/dashboard',
          state: { welcome: true }
        })
      })
      .catch((error) => {
        setApiError("We couldn't find an account with these details.");
        setLoading(false)
      });
  };

  const responseGoogle = (response) => {
    if (response) {
      new LoginService().googlelogin(response)
        .then((res) => {
          // toast.success('Login successful with Google')
          setValue('token', res.data.value.token);
          setValue('email', res.data.value.email);
            
          setValue('bussinessID', res.data.value.business_account_id);
          setValue('avatar', res.data.value.google_profile_picture_url);
          setLoading(false)
          history.push({
            pathname: '/dashboard',
            state: { welcome: true }
          })
        })
        .catch((err) => {
          var error = err.response.data.message
          toast.error(error);
          setLoading(false)
        })
    }
  }

  const handleError = (error) => {
    setLoading(false)
  }

  const handleClose = () => setOpen(false);

  const sHandleClose = () => setSuccesOpen(false);

  const onForgotClick = () => {
    const { email } = state;
    const { businessAccount } = state;
    setLoading(true)
    if (!email.length && !businessAccount.length) {
      setErrorMsg('All fields are required.');
      setLoading(false)
      return;
    }
    new LoginService().forgotPassword({ email: state.email, business_account: state.businessAccount })
      .then((response) => {
        setSuccesOpen(true)
        setLoading(false)
        handleClose()
      })
      .catch((error) => {
        setErrorMsg('We couldn’t find an account with that email.');
        setLoading(false)
      });
  };

    const handleSuccess = () => {
      setRedirect(false)
    }

    useEffect(()=>{
      setTimeout(() => {
        handleSuccess()
    }, 10000);
    },[])

    useLayoutEffect(() => {
        if (location.state) {
            if (location.state.welcome) {
                setRedirect(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])


  const getBusinessAccounts = async (email) => {
    try {
      new LoginService().usersBusinessAccounts(email)
        .then((res) => res.data)
        .then((res) => {
          if(res?.length > 0){
            setBusinessAccounts(res)
            setStep(2)
          }else {
            setApiError("No business account associated with this email");
            setStep(1)
          }
          setLoading(false)
        })
        .catch((error) => {
          toast.error("Something went wrong");
          setLoading(false)
        });
    } catch (error) {
      console.log('error', error)
      toast.error("Something went wrong");
      return []
    }
  }

  const onNextClick = async () => {
    try {
      const { email } = state;
      setLoading(true)
      if (!email.length) {
        setLoading(false)
        setApiError('Email is required.')
        setState(prevState => ({
          ...prevState,
          emailError: true,
        }))
        return;
      }
      getBusinessAccounts(email)
    } catch (error) {
      console.log('error', error)
      toast.error("Something went wrong");
    }
  }

  return (
    <>
      <div className="login">
        <ToastContainer
          position="bottom-left"
          autoClose={10000}
        />
        <div className="login__header">
        <span>
          <img src={logo} alt="logo" /> Kontoro labs
        </span>
        </div>
        <div className="login__title">
          <div className="login__title-text">
            Sign in
          </div>
        </div>
        <div className="login__container">
          {loading && <LinearProgress className="linear_progress" />}
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={renderProps => (
              <div className="login__container__title" onClick={() => { setLoading(true); renderProps.onClick() }} disabled={renderProps.disabled} >
                <div className="login__container__title__google">
                  <FcGoogle />
                </div>
                <div className="login__container__title__text">
                  Sign in with Google
                </div>
              </div>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={handleError}
            cookiePolicy={'single_host_origin'}
          />
          <div className="login__container__divider">or</div>
          <p className={`${apiError ? 'login__input__error-display' : 'login__input__error-hide'}`}>
            {apiError}
          </p>
          <form>
            <Input type="email" className={`login__input ${state.emailError ? 'error' : ''}`} id={"email"} placeholder="Email"
              value={state.email}
              onChange={handleChange}
              autoComplete="new-password"
            />
            {apiError ? '' :  <p className={`${indiEmailError ? 'login__input__error-display indi' : 'login__input__error-hide'}`}>
            {indiEmailError}
            </p>
            } 
            {
              step === 2
              &&
              <>
                <Input type="select"  className={`login__input ${state.businessAccountError ? 'error' : ''}`} id={"businessAccount"}
                  value={state.businessAccount}
                  onChange={handleChange} >
                  <option value="">Select Account</option>
                  {
                    businessAccounts.length > 0 && businessAccounts.map(businessAccount => (
                      <option value={businessAccount.id} key={businessAccount.id}>{businessAccount.owner_email}</option>
                    ))
                  }
                </Input>
                <PasswordInput id="password" className={`login__input ${state.passwordError ? 'error' : ''}`} placeholder="Password"
                  value={state.password}
                  onChange={handleChange}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      onLoginClick();
                    }
                  }}
                  autoComplete="new-password"
                  sx={{"& .MuiInputBase-input":{fontSize: "14px", paddingBottom: "0"}}}
                />
                {apiError ? '' : 
                <p className={`${indiPasswordError ? 'login__input__error-display indi' : 'login__input__error-hide'}`}>
                  {indiPasswordError}
                </p>
                }
              </>
            }
            <div className="login__container__footer" >
              {/* <FormControlLabel
                className="remember_me"
                id="rememberMe"
                control={<Checkbox icon={<UncheckCircleIcon className="uncheckedIcon" />} checkedIcon={<CheckCircleIcon className="checkedIcon" />} />}
                label="Remember Me"
                onChange={(e) => {
                  setState(prevState => ({
                    ...prevState,
                    rememberMe: e.target.checked
                  }))
                }}
              /> */}
              <Link to="#" onClick={() => setOpen(true)} className="forgot_link">Forgotten your password ?</Link>
              or
              <Link to="/signup" onClick={() => history.push('/signup')} className="forgot_link">
                Signup New Account
              </Link>
            </div>

            {
              step === 1
              ?
                <Button className="login__button"
                  onClick={onNextClick}>
                  NEXT
                </Button>
              :
                <Button className="login__button"
                  onClick={onLoginClick}>
                  SIGN IN
                </Button>
            }
          </form>
        </div>

        <div className="login__footer">
          <div className="login__footer__text">Want to know more ?</div>
          <Link
            className="login__footer__button"
            to="/talktorepresentative"
          >
            TALK TO A SALES REPRESENTATIVE
          </Link>
        </div>
      </div>

      {redirect ?
       <div className="dashboard__success__message">
            <div className={`dashboard__body__success`}>
                <div className="header__msg">
                <div className="success_icon_msg">Success</div>
                <div className="success-close" onClick={handleSuccess}>close</div>
                </div>
                <p className="success-msg">Your request has been sent successfully. We'll be in touch soon.</p>
            </div>
        </div> : null
        }
      <Dialog open={open} onClose={handleClose} className="forgot-dialog" >
        <DialogTitle className="dialog__title">
          Forgot your password
        </DialogTitle>
        <DialogContent className="forget__content">
          <Box width="sm">
            <form onSubmit={(e) => e.preventDefault()}>
              <p className="dialog__subtitle">We'll send you an email with a link to reset your password.</p>
              <Input type="email" className={`login__input ${errorMsg ? 'error' : ''}`} id={"email"} placeholder="Email" value={state.email}
                onChange={handleChange}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    onForgotClick();
                   }
                 }}
              />
              <Input type="businessAccount" className={`login__input ${errorMsg ? 'error' : ''}`} id={"businessAccount"} placeholder="business account" value={state.businessAccount}
                onChange={handleBusinessAccount}
                 onKeyDown={e => {
                  if (e.key === 'Enter') {
                    onForgotClick();
                  }
                }}
              />
               <p
                className={`${errorMsg ? 'login__input__error-display forgot' : 'login__input__error-hide'}`}
              >
                {errorMsg}
              </p>
              <Button className="login__button forgot mt-3"
                onClick={onForgotClick}>
                SEND EMAIL
              </Button>

              <div className="login__container__footer forgot" >
                <Link to="/login" className="forgot_link" onClick={handleClose}>Back to sign in page</Link>
                <Link to="/talktorepresentative" className="forgot_link">Still can’t login ? Contact us.</Link>
              </div>
            </form>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={succesOpen} onClose={sHandleClose} className="forgot-dialog success__dialog" >
        <DialogContent className="forget__content success__content">
          <Box width="sm">
              <p className="dialog__subtitle">A link to reset your password has been sent to your email.</p>
              <Button className="sClose__button"
              onClick={sHandleClose}>
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

    </>
  )
};

export default Login;
