import React, { useEffect, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../apiIntegration/index.scss';
import { Tabs, Tab, Dialog, DialogContent} from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

const BASE_URL = process.env.REACT_APP_BASE_API_URL
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};
const PopUpApiIntegration = ({apiPopUp, apiPopupClose,secret, apiKey, gateId = "gate_id" }) => {
    const [tab, setTab] = useState("CURL");
    const handleChange = (e, newValue) => {
        setTab(newValue);
    };
    const [curlCode, setCurlCode] = useState("")
    const [nodeJsCode, setNodeJsCode] = useState("")
    const [swiftCode, setSwiftCode] = useState("")
    const [pythonCode, setPythonCode] = useState("")
    const [flutterCode, setFlutterCode] = useState("")
    
    useEffect(() => {
        setCurlCode(`
        curl --location --request GET '${BASE_URL}/gates/${gateId}/variation-for-user/<user_unique_identifier>' 
        --header 'API-SECRET: ${secret || "<api_secret>"}' 
        --header 'API-KEY: ${apiKey || "<api_key>"}'
        `)
        setNodeJsCode(`
        var axios = require('axios');
      
        var config = {
          method: 'GET',
          url: '${BASE_URL}/gates/${gateId}/variation-for-user/<user_unique_identifier>',
          headers: { 
              'API-SECRET': '${secret || "<api_secret>"}',
              'API-KEY': '${apiKey || "<api_key>"}'
          },
          // We recommend adding a timeout to avoid blocking request to Kontoro Servers. In this example we are using a 2 second timeout.
          timeout: 2000 
        };
      
        axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });`)
        setSwiftCode(`
        import Foundation
        #if canImport(FoundationNetworking)
        import FoundationNetworking
        #endif

        var semaphore = DispatchSemaphore (value: 0)

        // We recommend adding a timeout to avoid blocking request to Kontoro Servers. In this example we are using a 2 second timeout.
        var request = URLRequest(url: URL(string: "${BASE_URL}/gates/${gateId}/variation-for-user/<user_unique_identifier>")!,timeoutInterval: TimeInterval = 2.0)
        request.addValue("${secret || "<api_secret>"}", forHTTPHeaderField: "API-SECRET")
        request.addValue("${apiKey || "<api_key>"}", forHTTPHeaderField: "API-KEY")

        request.httpMethod = "GET"

        let task = URLSession.shared.dataTask(with: request) { data, response, error in 
            guard let data = data else {
            print(String(describing: error))
            semaphore.signal()
            return
            }
            print(String(data: data, encoding: .utf8)!)
            semaphore.signal()
        }

        task.resume()
        semaphore.wait()
        `)
        setPythonCode(`
        import http.client

        # We recommend adding a timeout to avoid blocking request to Kontoro Servers. In this example we are using a 2 second timeout.
        conn = http.client.HTTPSConnection("${BASE_URL}", 80, timeout=2)
        payload = ''
        headers = {
            'API-SECRET': '${secret || "<api_secret>"}',
            'API-KEY': '${apiKey || "<api_key>"}'
        }
        conn.request("GET", "/gates/${gateId}/variation-for-user/<user_unique_identifier>", payload, headers)
        res = conn.getresponse()
        data = res.read()
        print(data.decode("utf-8"))
        `)
        setFlutterCode(`
        var headers = {
            'API-SECRET': '${secret || "<api_secret>"}',
            'API-KEY': '${apiKey || "<api_key>"}'
        };
        
        var request = http.Request('GET', Uri.parse('${BASE_URL}/gates/${gateId}/variation-for-user/<user_unique_identifier>'));
        
        request.headers.addAll(headers);
        
        // We recommend adding a timeout to avoid blocking request to Kontoro Servers. In this example we are using a 2 second timeout.
        http.StreamedResponse response = await request.send().timeout(const Duration(seconds: 2));
        
        if (response.statusCode == 200) {
            print(await response.stream.bytesToString());
        }
        else {
            print(response.reasonPhrase);
        }
        `)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secret, apiKey, gateId])

    return (
        <>
         <Dialog open={apiPopUp} className="apiIntegration-dialog apiIntegration_popup" >
        <DialogContent className="apiIntegration__content">
          <Box width="sm">
              <div className="apiIntegration__header">
                  <p>API Integration</p>
                  <button type="button" class="btn btn-outline-primary" onClick={apiPopupClose}>Close</button>
              </div>
            <div className="credential__body__list">
                <div className="tab__header">
                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        // variant="scrollable"
                        // scrollButtons="auto"
                        // aria-label="scrollable auto tabs example"
                        TabIndicatorProps={{
                            style: {
                                display: "none",
                            },
                        }}
                    >
                        <Tab
                            label="CURL"
                            className={`tab__header__item ${(tab === "CURL") ? 'active' : ''}`}
                            value="CURL"
                        />
                        <Tab
                            className={`tab__header__item ${(tab === "NodeJS") ? 'active' : ''}`}
                            label="NodeJS"
                            value="NodeJS"
                        />
                        <Tab
                            className={`tab__header__item ${(tab === "Swift") ? 'active' : ''}`}
                            label="Swift"
                            value="Swift"
                        />
                        <Tab
                            className={`tab__header__item ${(tab === "Python") ? 'active' : ''}`}
                            label="Python"
                            value="Python"
                        />
                        <Tab
                            className={`tab__header__item ${(tab === "Flutter") ? 'active' : ''}`}
                            label="Flutter"
                            value="Flutter"
                        />
                    </Tabs>
                </div>
                <div className="tab__body">
                    <TabPanel value={tab} index={"CURL"}>
                        <SyntaxHighlighter language="javascript" style={a11yDark}>
                            {curlCode}
                        </SyntaxHighlighter>
                    </TabPanel>
                    <TabPanel value={tab} index={"NodeJS"}>
                        <SyntaxHighlighter language="javascript" style={a11yDark}>
                            {nodeJsCode}
                        </SyntaxHighlighter>
                    </TabPanel>
                    <TabPanel value={tab} index={"Swift"}>
                        <SyntaxHighlighter language="swift" style={a11yDark}>
                            {swiftCode}
                        </SyntaxHighlighter>
                    </TabPanel>
                    <TabPanel value={tab} index={"Python"}>
                        <SyntaxHighlighter language="python" style={a11yDark}>
                            {pythonCode}
                        </SyntaxHighlighter>
                    </TabPanel>
                    <TabPanel value={tab} index={"Flutter"}>
                        <SyntaxHighlighter language="dart" style={a11yDark}>
                            {flutterCode}
                        </SyntaxHighlighter>
                    </TabPanel>
                </div>
            </div>
            </Box>
        </DialogContent>
      </Dialog>
    </>
    )
}

export default PopUpApiIntegration;
