import React, { useState, useLayoutEffect } from 'react';
import { Input, Button } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import LoginService from '../../services/http/loginService';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../shared/loader';
import { PasswordInput } from '../shared/input';
import { checkPassword } from '../../utils';

const SignUp = () => {
  const history = useHistory();
  const search = useLocation().search;
  const api_key = new URLSearchParams(search).get('api_key');

  const [businessAccounts, setBusinessAccounts] = useState([])

  useLayoutEffect(() => {
    if (!api_key) history.push('/')
    new LoginService().businessAccounts()
      .then((res) => res.data)
      .then((res) => res.value)
      .then((res) => {
        setBusinessAccounts(res?.paginate_records)
      })
      .catch((error) => {
        console.log(error)
      });
  }, [api_key, history])

  const [apiError, setApiError] = useState('');
  const [state, setState] = useState({
    business_account_id: "",
    first_name: "",
    firstNameError: false,
    last_name: "",
    lastNameError: false,
    email: "",
    emailError: false,
    password: "",
    passwordError: false,
    confirmPassword: "",
    confirmPasswordError: false,
  });
  const { first_name, firstNameError, last_name, lastNameError, email, emailError, password, passwordError, confirmPassword, confirmPasswordError } = state

  const validateEmail = (value) => {
    const mailformat = "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$";
    if (value.match(mailformat)) {
      return true;
    }
    else {
      return false;
    }
  };

  const handleChange = (e) => {
    setApiError('')
    const { id, value } = e.target;
    setState(prevState => ({
      ...prevState,
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      passwordError: false,
      confirmPasswordError: false,
      [id]: value
    }))
  };

  const [loading, setLoading] = useState(false)

  const onRegister = () => {
    setLoading(true)
    if (!first_name.length && first_name.startsWith(" ")) {
      setLoading(false)
      setState(prevState => ({
        ...prevState,
        firstNameError: true,
      }))
      return setApiError("First Name is required");
    }
    if (!last_name.length && last_name.startsWith(" ")) {
      setLoading(false)
      setState(prevState => ({
        ...prevState,
        lastNameError: true,
      }))
      return setApiError("Last Name is required");
    }
    if (!email.length) {
      setLoading(false)
      setState(prevState => ({
        ...prevState,
        emailError: true,
      }))
      return setApiError("Email is required");
    } else if (!validateEmail(email)) {
      setLoading(false)
      setApiError('The email you entered is incorrect.')
      setState(prevState => ({
        ...prevState,
        emailError: true,
      }))
      return;
    }
    if (!password.length) {
      setLoading(false)
      setState(prevState => ({
        ...prevState,
        passwordError: true,
      }))
      return setApiError("Password is required");
    } else {
        let err = checkPassword(password, 'Password')
        if(err !== true) {
          setLoading(false)
          setState(prevState => ({
            ...prevState,
            passwordError: true,
          }))
          return setApiError(err);
        }
    }
    if (!confirmPassword.length) {
      setLoading(false)
      setState(prevState => ({
        ...prevState,
        confirmPasswordError: true,
      }))
      return setApiError("Confirm Password is required");
    }
    if(password !== confirmPassword ){
      setLoading(false)
      setState(prevState => ({
          ...prevState,
          passwordError: true,
          confirmPasswordError: true,
      }))
      return setApiError("Password and Confirm Password do not match.");
    } else {
        let err = checkPassword(confirmPassword, 'Confirm Password')
        if(err !== true) {
          setLoading(false)
            setState(prevState => ({
                ...prevState,
                confirmPasswordError: true,
            }))
            return setApiError(err);
        }
    }

    new LoginService().register({
      api_key,
      business_account_id: state.business_account_id,
      first_name: state.first_name,
      last_name: state.last_name,
      email: state.email,
      password: state.password,
      password_confirmation: state.confirmPassword
    })
      .then((response) => {
        toast.success('Sing Up Successful');
        history.push('/login');
      }).catch((err) => {
        setLoading(false)
        if (err.response.data) {
          setApiError(err.response.data.message);
        }
      });
  }

  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <div className="login">
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
        />
        <div className="login__header">
          KONTORO
        </div>
        <div className="login__title">
          <div className="login__title-text">
            Sign Up
          </div>
        </div>

        <div className="login__container">
          {loading && <LinearProgress className="linear_progress" />}
          <p
            className={`${apiError ? 'login__input__error-display' : 'login__input__error-hide'}`}
          >
            {apiError}
          </p>

          <form>
            <Input type="select" className="login__input" id={"business_account_id"}
              value={state.business_account_id}
              onChange={handleChange} >
              <option value="">Create New Account</option>
              {
                businessAccounts.length > 0 && businessAccounts.map(businessAccount => (
                  <option value={businessAccount.id} key={businessAccount.id}>{businessAccount.owner_email}</option>
                ))
              }
            </Input>

            <Input type="text" className={`login__input ${firstNameError ? 'error' : ''}`} id={"first_name"} placeholder="First Name *" value={first_name}
              onChange={handleChange} />
            <Input type="text" className={`login__input ${lastNameError ? 'error' : ''}`} id={"last_name"} placeholder="Last Name *" value={last_name}
              onChange={handleChange} />
            <Input type="email" className={`login__input ${emailError ? 'error' : ''}`} id={"email"} placeholder="Email *" value={email}
              onChange={handleChange} />
            <PasswordInput id="password" className={`login__input ${passwordError ? 'error' : ''}`} placeholder="Password"
              value={password} onChange={handleChange}
              autoComplete="new-password"
              sx={{"& .MuiInputBase-input":{fontSize: "14px", paddingBottom: "0"}}}
            />
            <PasswordInput id="confirmPassword" className={`login__input ${confirmPasswordError ? 'error' : ''}`} placeholder="Confirm Password *"
              value={confirmPassword} onChange={handleChange}
              autoComplete="new-password"
              sx={{"& .MuiInputBase-input":{fontSize: "14px", paddingBottom: "0"}}}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onRegister();
                }
              }}
            />

            <Button className="login__button"
              // disabled={!state.email.length || !state.password.length || !!emailError.length}
              onClick={onRegister}>
              Register
            </Button>
          </form>
        </div>
      </div>
    </>
  )
}

export default SignUp;