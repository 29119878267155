import React, {useEffect, useState} from 'react'
import Header from '../shared/header'
import styled from "styled-components"
import { Link } from 'react-router-dom';
import "./logdetails.scss"
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { TopArrow, LeftArrow, RightArrow } from '../../assets/icons';
import GatesService from '../../services/http/gatesService';
import { getValue } from '../../services/storageService';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const opacity1={
    opacity: '1',
    transition:'all 0.1s ease-in-out',
    backgroundColor:"transparent",
    border: 'none',
    outline:'none'
}
const opacity0={
    opacity: '0'
}

const LogDetails = () => {


    const [isVisible, setIsVisible] = useState(false);
    const [timelineData, setTimelineData] = useState([])
    const [paginateGates, setPaginateGates] = useState({});
    const [loading, setLoading] = useState(false);

    const toggleVisibility=()=>{
        if(window.pageYOffset > 50){
            setIsVisible(true)
        }else{
            setIsVisible(false);
        }
    }

    const scrollToTop=()=>{
        window.scrollTo({
            top:0,
            behavior: 'smooth',
        })
    }

    const fetchGates=(filter)=>{
        setLoading(true)
        const id = getValue('bussinessID')
        new GatesService().logScreen(id, filter).then(response => {
            const data= response.data.value.paginate_records
            const paginationData = response.data.value.pagination_data;
            setTimelineData(data)
            setLoading(false)
            setPaginateGates(paginationData)
          }).catch(error => {
              console.error(error);
          })
  
    }


    useEffect(()=>{
        fetchGates({per_page:30, page:1})
          window.addEventListener('scroll', toggleVisibility);
          return ()=>{
              window.removeEventListener('scroll', toggleVisibility);
          }
    },[]);
       

    const handlePageClick=(page)=>{
        fetchGates({per_page:30, page: page.selected + 1});
    }

  return (
    <div>
          <Header />
            <div className="log__details">
                <div style={{ padding: "0" }} className="logDetails__body">
                <Div2 className='logDetails__container'>
                    <div className="logDetails__content">
                        <div className="heading">
                            Log
                        </div>
                {
                    loading
                    ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '5em' }}>
                        <CircularProgress color="inherit" />
                    </Box>
                    :
                    <div className="log__indi__container">
                    {timelineData.map((timeline, index)=>(
                        <>
                            {timeline.event === "Gate Created" &&  timeline.item_type === "Gate" &&
                                <div className="log__indi__container__content">
                                <p><span className='name'>{timeline.object_changes?.to?.name} </span> <span style={{color:"#22222"}}>(ID : {timeline.object_changes?.to?.id})</span> with <span style={{color:"#22222"}}>{timeline.object_changes?.meta?.variation_count} variations</span>  has been created by <span style={{color:"#22222"}}> {timeline.username}</span></p>
                                    <p>{moment(timeline.created_at).format('MM/DD/YYYY hh:mm:ss A')}</p>
                                </div>
                            }
                            {timeline.event === "Gate Changes" &&  timeline.object_changes.key === "state" &&
                                <div className="log__indi__container__content">
                                <p><span className='name'>{timeline.object_changes.meta.name}</span> <span className='name'> (ID: {timeline.object_changes?.meta?.id})</span> has been <span style={{color:"#22222"}}>{timeline.object_changes.to}</span> by <span style={{color:"#22222"}}>{timeline.username}</span></p>
                                    <p>{moment(timeline.created_at).format('MM/DD/YYYY hh:mm:ss A')}</p>
                                </div>
                            }
                            {timeline.event === "Gate Changes" &&  timeline.object_changes.key === "name" &&
                                <div className="log__indi__container__content">
                                <p><span className='name'>{timeline.object_changes.from} (ID: {timeline.object_changes?.meta?.id})</span> name has been changed to <span style={{color:"#22222"}}>{timeline.object_changes.to}</span> by <span style={{color:"#22222"}}>{timeline.username}</span></p>
                                    <p>{moment(timeline.created_at).format('MM/DD/YYYY hh:mm:ss')}</p>
                                </div>
                            }
                            {timeline.event === "Gate Changes" &&  timeline.object_changes.key === "global_traffic" && timeline.item_type === "Gate" &&
                                <div className="log__indi__container__content">
                                <p><span className='name'>{timeline.object_changes.meta.name}</span> <span style={{color:"#22222"}}> (ID : {timeline.object_changes.meta.id})</span> global traffic has been changed to <span style={{color:"#22222"}}>{timeline.object_changes.to}</span> from <span style={{color:"#22222"}}>{timeline.object_changes.from}</span>  by <span style={{color:"#22222"}}>{timeline.username}</span></p>
                                    <p>{moment(timeline.created_at).format('MM/DD/YYYY hh:mm:ss')}</p>
                                </div>
                            }
                            {timeline.event === "Variation Created" &&  timeline?.item_type === "Variation" && timeline.object_changes.key === "new" &&
                                <div className="log__indi__container__content">
                                <p><span className='name'>{timeline.object_changes?.to?.name}</span> <span style={{color:"#22222"}}> (ID : {timeline.object_changes?.to?.id})</span> has been added to <span style={{color:"#22222"}}>{timeline.object_changes.meta?.gate_name} (ID: {timeline.object_changes?.to?.gate_id}) </span>  by <span style={{color:"#22222"}}>{timeline.username}</span></p>
                                    <p>{moment(timeline.created_at).format('MM/DD/YYYY hh:mm:ss')}</p>
                                </div>
                            }
                            {timeline.event === "Variation Deleted" &&  timeline.item_type === "Variation" && timeline.object_changes.key === "deleted" &&
                                <div className="log__indi__container__content">
                                <p><span className='name'>{timeline.object_changes?.from?.name} (ID: {timeline.object_changes?.from?.id})</span> has been deleted from <span style={{color:"#22222"}}>{timeline.object_changes?.meta?.gate_name} (ID: {timeline.object_changes?.from?.gate_id}) </span> by <span style={{color:"#22222"}}> {timeline.username}</span> </p>
                                    <p>{moment(timeline.created_at).format('MM/DD/YYYY hh:mm:ss')}</p>
                                </div>
                            }
                            {timeline.object_changes.key === "traffic_percentage"  && timeline.object_changes?.from === null  ? null :
                            <>
                                {timeline.event === "Variation Changes" &&  timeline.item_type === "Variation" && timeline.object_changes.key === "traffic_percentage" &&
                                <div className="log__indi__container__content">
                                <p><span style={{color:"#22222"}}>{timeline.object_changes?.meta?.name} <span style={{color:"#22222"}}> (ID: {timeline.object_changes?.meta?.id})</span></span> traffic has been changed to <span style={{color:"#22222"}}>{timeline.object_changes?.to}</span> from <span style={{color:"#22222"}}>{timeline.object_changes?.from}</span>  in <span className='name'>{timeline.object_changes?.meta?.gate_name} (ID: {timeline.object_changes?.meta?.gate_id})</span> by <span style={{color:"#22222"}}> {timeline.username}</span></p>
                                <p>{moment(timeline.created_at).format('MM/DD/YYYY hh:mm:ss')}</p>
                                </div>
                            }
                            </>
                            }
                            {timeline.event === "Variation Changes" &&  timeline.item_type === "Variation" && timeline.object_changes.key === "code" &&
                                <div className="log__indi__container__content">
                                <p><span className='name'>{timeline.object_changes.meta?.gate_name} (ID: {timeline.object_changes.meta?.gate_id})</span> JSON in <span className='name'>{timeline.object_changes.meta.name} (ID: {timeline.object_changes.meta.id})</span> has been updated by <span style={{color:"#22222"}}> {timeline.username}</span></p>
                                    <p>{moment(timeline.created_at).format('MM/DD/YYYY hh:mm:ss')}</p>
                                </div>
                            }
                         </>
                    ))}
                        </div>
                }
                    {timelineData.length === 0 ? null :
                        <ReactPaginate 
                        previousLabel={<LeftArrow />}
                        nextLabel={<RightArrow />}
                        breakLabel={'...'}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageCount={paginateGates.max_page}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        forcePage={paginateGates.curr_page ? paginateGates.curr_page-1 : null }
                        />
                    }
                    <div className="arrow__content">
                        <button
                            type='button'
                            onClick={scrollToTop}
                            className="scrollToTop"
                            style={isVisible ? opacity1 : opacity0}
                            >
                            <TopArrow />
                        </button>
                    </div>
                    </div>
                </Div2>
            </div>
            </div>
    </div>
  )
}

const Div2 = styled.div`
    width: 100%;
    padding: 45px 55px;
    margin: 0;
    background: transparent;
`


export default LogDetails