//imported in forms/gates

import React from 'react'
import { Dialog, DialogContent } from '@mui/material';
import Button from '../shared/button'
import { CopyIcon } from '../../assets/icons';
import { GATE_STATES } from '../../constants';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/webpack-resolver";
import { Box } from '@mui/system';


const VariationDetails = ({isUpdated, variationIndiError, indiError, jsonCopied, setLoading, isValidForm, msg, index, variation,handleVariationChange, curState, handleDelete, cnt, editAction, handleCodeChange }) => {
    const [jsonPopUp, setJsonPopUp] = React.useState(false);

    const handleSave= (jsonClick) =>{
        editAction(curState,jsonClick);
        if (!isValidForm()) return setLoading(false);
        setJsonPopUp(false);
    }
    
    const jsonPopUpClose = () =>{
        setJsonPopUp(false);
    }
    const jsonPopUpOpen = () =>{
        setJsonPopUp(true);
    }

    const handleCopyClick = ()=>{
        navigator.clipboard.writeText(JSON.stringify(variation.code, null , '\t'))
        jsonCopied()
    }


  return (
    <div className="drawer__body__entity bottom__field">
        <text className={`drawer__body__entity__title ${msg.traffic && 'error'}`}>
            <span className={`drawer__body__entity__title__label`}>{variation.name}</span>
            <span className={`drawer__body__entity__title__id`}>{ variation.id ? `ID ${variation.id}` : 'Save to create variation ID'}</span>
            {<span className={`drawer__body__entity__title__traffic ${variationIndiError && 'error__input__variation'}`}><input disabled={curState === "archived"} type="number" className={`drawer__body__entity__title__traffic__input`} name={index} onChange={handleVariationChange}  value={variation.traffic_percentage === '' ? variation.traffic_percentage = 0 : variation.traffic_percentage}/>%</span>}
            {<span className={`drawer__body__entity__title__desc `}>
                <input disabled={curState === "archived"} type="text" placeholder='Add text to describe the variation (optional)' 
                    className={`drawer__body__entity__title__desc__input`} 
                    name={index} 
                    onChange={(e) => handleVariationChange(e, 'description')}
                    value={variation.description}
                />
            </span>}
            <Button className={`drawer__body__entity__title__jsonBtn`} onClick={jsonPopUpOpen} disabled={indiError ? "disabled" : null}>JSON</Button>
            <div className={`drawer__body__entity__title__copyBtn`} style={{cursor:"pointer"}} onClick={handleCopyClick}><CopyIcon /></div>
            {curState !== GATE_STATES[1] && curState !== GATE_STATES[3] ?
            <>
            {
                index !== 0 && (curState === GATE_STATES[0]) ?
                        <Button variant="outlined" className="btn-secondary-outlined delete__btn" onClick={() => handleDelete(variation, index, cnt - 1)}>delete</Button>
                    :
                    (variation._newVariation === true) ?
                            <Button variant="outlined" className="btn-secondary-outlined delete__btn" onClick={() => handleDelete(variation, index, cnt - 1)}>delete</Button>
                        : 
                            <Button style={{ visibility: "hidden"}} variant="outlined" className="btn-secondary-outlined h-sm w-sm font-size-13 hide delete__btn">delete</Button>
            }
            </>
            : null
            }
        </text>
        <Dialog open={jsonPopUp} key={index} onClose={jsonPopUpClose} className="jsonForm-dialog jsonForm_popup" >
            <DialogContent className="jsonForm__content">
            <Box width="sm">
                <div className="jsonPopUp__header">
                    <p>JSON</p>
                    <div className="jsonAction__cta">
                        <button type="button" class="btn btn-outline-primary" onClick={jsonPopUpClose}>Close</button>
                       {  curState === GATE_STATES[3] ? null : <Button className='btn-outline-primary save__btn' onClick={(jsonClick)=>handleSave(jsonClick)} disabled={!isUpdated}>Save</Button>  }
                    </div>
                </div>
                <p className='json__subtitle'>Edit or check code of variation</p>
               {variation.error  ? <p className='indi__error'>Invalid Script</p> : null}
                <div className="drawer__body__entity__form">
                    <div className={`drawer__body__entity__form__code ${variation.error ? 'error' : ''}`}>
                        <AceEditor
                            name={index}
                            mode="json"
                            theme="monokai"
                            // width='100%'
                            showGutter={true}
                            readOnly={curState === GATE_STATES[3]}
                            setOptions={{
                                showLineNumbers: false,
                                tabSize: 2,
                                minLines: 4,
                                maxLines: 30,
                                autoScrollEditorIntoView: true,
                                animatedScroll: true,
                            }}
                            defaultValue={JSON.stringify(variation.code, null , '\t')}
                            onChange={(v) => handleCodeChange(index, v)}
                            editorProps={{$blockScrolling: false}}
                        />
                    </div>
                    {/* <div className="drawer__body__entity__form__control">
                        <KontoroSlider
                            aria-label="global traffic"
                            name={index}
                            value={variation.traffic_percentage}
                            onChange={handleVariationChange}
                            disabled={curState === GATE_STATES[3]}
                        />
                    </div> */}
                    {/* <div className="drawer__body__entity__form__label">
                        {variation.traffic_percentage} %
                    </div> */}
                    {/* {
                        (index > 0 && variations.length > 2 && curState === GATE_STATES[0]) ?
                            <div className="drawer__body__entity__form__button">
                                <Button variant="outlined" className="btn-primary-outlined h-sm w-sm font-size-11" onClick={() => handleDelete(variation, index, cnt - 1)}>DELETE</Button>
                            </div>
                            :
                            (variation._newVariation === true) ?
                                <div className="drawer__body__entity__form__button">
                                    <Button variant="outlined" className="btn-primary-outlined h-sm w-sm font-size-11" onClick={() => handleDelete(variation, index, cnt - 1)}>DELETE</Button>
                                </div>
                                
                                : <div className="drawer__body__entity__form__button">
                                    <Button variant="outlined" className="btn-primary-outlined h-sm w-sm font-size-11 hide">DELETE</Button>
                                </div>
                    } */}
                    {/* <textarea name="paragraph_text" cols="50" rows="3"></textarea> */}
                </div>
            </Box>
            </DialogContent>
        </Dialog>
    </div>
  )
}

export default VariationDetails