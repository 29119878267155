import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import GatesList from '../list/gates';
import { Table } from 'reactstrap';
import GateForm from '../forms/gates';
import './gates.scss';
import GatesService from '../../services/http/gatesService';
import { LinearProgress, Tabs, Tab, TextField, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
import Header from '../shared/header';
// import Footer from '../shared/footer';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { ArchivedIcon, BreakIcon, DraftIcon, RunningIcon } from '../../assets/icons';
// import BreakIcon from "../../assets/images/icons/break-icon.png"
import Dialog from '../shared/dialog'
import Button from '../shared/button'
import { closePrompt, prompt } from '../shared/prompt';
import { GATE_STATES, WrongUrlCols } from '../../constants';
import styled from "styled-components"
import { Link } from 'react-router-dom';
import CredentialsService from '../../services/http/credentialsService';
import reactPaginate from 'react-paginate';
import { Select, selectClasses } from '@mui/base/Select';
import { Option, optionClasses } from '@mui/base/Option';
import { Popper } from '@mui/base/Popper';
import { styled as styledMu } from '@mui/system';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#3C4043C7',
};

const StyledButton = styledMu('button')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 184px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${selectClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const StyledListbox = styledMu('ul')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 184px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  position: relative;
  `,
);

const StyledOption = styledMu(Option)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `,
);

const StyledPopper = styledMu(Popper)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <Select {...props} ref={ref}  slots={{ root: StyledButton, listbox: StyledListbox }}  components={components} />;
});

export const blockInvalidChar = e => ['e', 'E', '+', '-','.'].includes(e.key) && e.preventDefault();

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

const Gates = () => {
    const history = useHistory();
    const inputRef = useRef(null);
    const archiveMsgRef = useRef(null);
    const blankMsgRef = useRef(null);


    const params = useParams()
    const [gates, setGates] = useState({});
    const [paginateGates, setPaginateGates] = useState({});
    // const [selectedMenu, setSelectedMenu] = useState('gates');
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const [selectedGate, setSelectedGate] = useState(null);
    const [editGate, setEditGate] = useState(false);
    const [loading, setLoading] = useState(false)
    const [welcome, setWelcome] = useState(false)
    const location = useLocation();
    const [indiCounting, setIndiCounting] = useState({})
    const [indiError, setIndiError] = useState('')
    const [sameNameError, setSameNameError] = useState("")
    const [variationindiError, setVariationIndiError] = useState('')
    const [checkUrl, setCheckUrl] = useState(false)
    useLayoutEffect(() => {

        if (location.state) {
            if (location.state.welcome) {
                setWelcome(true)
            }
            setState(prevState => ({
                ...prevState,
                successMsg: location.state.msg,
                successType: location.state.type || "",
            }))
            closeMsg()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])


    const globalData = ({ filter, onload }) => {
        new GatesService().fetchGates().then(response => {


            setWelcome(false)
            setLoading(false)
        })
        new GatesService().fetchGates({counts_only:true}).then(response => {
            const data = response.data.value;
            setIndiCounting(data)
        })
    }

    const totalData = () => {
        return Object.values(indiCounting).reduce((total, value) => total + value, 0)
    }
    const fetchGates = async ({ filter = {}, onload, createdGate }) => {
        await globalData({ filter, onload })
        setLoading(onload)
        new GatesService().fetchGates({ ...filter }).then(response => {
            const data = response.data.value.paginate_records;
            const paginationData = response.data.value.pagination_data;
            const sortedData = data.sort((a,b)=>{b.created_at?.localeCompare(a?.created_at)})
            setGates(sortedData);
            setPaginateGates(paginationData)
            // if(openDrawer){
            //     openDrawerFunctions(createdGate)
            // }
            setWelcome(false)
            setLoading(false)
        }).catch(error => {
            console.log(error);
            setWelcome(false)
            setLoading(false)
        })
    }

    useEffect(() => {
        let state = params.state !== 'all' ? indiCounting.draft !== undefined ? params.state || 'draft' : params.state || 'all' : 'all'
        fetchGates({ filter:  { "q[state_eq]": state === "all" ? "" : state, per_page:15},  onload: true });
        if(GATE_STATES.indexOf(params.state) === -1){
            if(params.state !== "all"){
                if(params.state !== undefined){
                    setCheckUrl(true)
                }
            }
        }else{
            setCheckUrl(false)
        }
        setTab(state)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, indiCounting.draft]);

    // Dropdown

    const handlePageClick=(page)=>{
        let state = params.state !== 'all' ? indiCounting.draft !== undefined ? params.state || 'draft' : params.state || 'all' : 'all'
        fetchGates({ filter:  { "q[state_eq]": state === "all" ? "" : state, per_page:15, page: page.selected + 1},  onload: true });
    }

    const initialState = {
        name: "",
        nameError: "",
        nameCount: 0,
        variation: "",
        variationError: "",
        successMsg: "",
        successType: "",
    }

    const [open, setOpen] = React.useState(false)
    const handleClose = () => {
        setIndiError("")
        setVariationIndiError("")
        setSameNameError("")
        setState(prevState => ({
            ...prevState,
            name: "",
            nameError: "",
            nameCount: 0,
            variation: "",
            variationError: "",
        }));
        setOpen(false);
    }

    const [tab, setTab] = useState("draft");
    const handleChange = (e, newValue) => {
        history.push(`/dashboard/${newValue}`)
        setTab(newValue);
    };

    const [state, setState] = useState(initialState)

    const handleSuccess = () => {
        setState(prevState => ({
            ...prevState,
            successMsg: "",
            successType: "",
        }))
    }
    const closeMsg = () => {
        setTimeout(() => {
            handleSuccess()
        }, 10000);
    }

    const handleState = (e) => {
        const { id, value } = e.target;
        setIndiError("")
        setVariationIndiError("")
        setSameNameError("")
        if (id === 'name') {
            setState(prevState => ({
                ...prevState,
                [id]: value,
                nameError: "",
                variationError: "",
                nameCount: value.split('').length
            }))
        } else {
            setState(prevState => ({
                ...prevState,
                [id]: value,
                nameError: "",
                variationError: "",
            }))
        }
    }

    const isValidGateName = (gate = "") => {
        let gateCount = gate.length
        if (gate === "") {
            setState(prevState => ({
                ...prevState,
                nameError: "These fields are required.",
            }))
            return false
        }
        // if (gate !== "") {
        //     const format = "^[a-zA-Z ]+[a-zA-Z0-9-_ ]+$";
        //     if (!gate.match(format)) {
        //         setState(prevState => ({
        //             ...prevState,
        //             nameError: "Please enter a valid Gate Name.",
        //         }))
        //         return false
        //     }
        // }
        if (gateCount < 1 || gateCount > 105) {
            setState(prevState => ({
                ...prevState,
                nameError: "Please use between 1 to 105 letters.",
            }))
            return false
        }
        return true
    }

    const isValidVariation = (variation = "") => {
        if (variation === "") {
            setState(prevState => ({
                ...prevState,
                variationError: "This is required.",
            }))
            return false
        }
        if (variation < 2 || variation > 10) {
            setState(prevState => ({
                ...prevState,
                variationError: "The number of variations is between 2 to 10.",
            }))
            return false
        }
        return true
    }

    const isValidForm = () => {
        const isValidGate = isValidGateName(state.name.trimStart())
        const isVariationValid = isValidVariation(state.variation)
        if(!isValidGate && isVariationValid){
            setIndiError("This field is required.")
            return false
        }else if(isValidGate && !isVariationValid){
            setVariationIndiError("- This field is required.")
            return false
        }
        else if (!isValidGate || !isVariationValid) {
            return false
        }
        return true
    }

    const onCreate = () => {
        if (!isValidForm()) return
        setLoading(true)

        const payload = {
            global_traffic: '100',
            name: state.name,
            variations_count: state.variation
        }
        new GatesService().createGate(payload).then(response => {
            setState(prevState => ({
                ...prevState,
                name: "",
                variation: "",
                nameCount: 0,
                successMsg: `${state.name} was created successfully. Click on any of the Gate names in the list above to configure them.`
            }))

            closeMsg()
            fetchGates({ filter: { "q[state_eq]": 'draft' }, createdGate: response.data.value});
            history.push('/dashboard/draft')
            handleClose()
        }).catch(error => {
            console.error(error);
            setSameNameError(error.response.data.message.slice(18))
            setLoading(false)
        })
    }

    const [apiData, setApiData] = useState(null)

    const fetchData = () => {
        setLoading(true)
        new CredentialsService().getCredentials().then(response => {
            const res = response.data.value;
            setApiData(res);
            setLoading(false)
        }).catch(error => {
            console.log(error);
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    if (welcome) {
        return (
            <div className="authContainer">
                <div className="loader">
                    <span className="loader__title">WELCOME TO KONTORO</span>
                    <LinearProgress className="linear_progress" />
                </div>
            </div>
        )
    }

    const onEdit = (gate, state) => {
        const { name } = gate
        switch (state) {
            case GATE_STATES[1]:
                prompt(`Are you sure you want to start the experiment ?`,
                    <div className='running_popup'>
                    <ul>
                        <li>Global traffic is 100%</li>
                        <li>{name} will be running after you click on RUN</li> 
                        <li>When a gate is running, the variations can not be modified or deleted</li>
                        <li>Only the gate name and traffic can be updated</li>
                    </ul>
                    </div>,
                    [
                        {
                            label: <Button variant="outlined" className="mx-3" style={{padding:'1px 34px', textTransform:'capitalize', borderRadius:'5px',background: '#FFFFFF', border: '1px solid #D5D9FE', color:'#233DFF'}}>Close</Button>,
                        },
                        {
                            label: <button style={{backgroundColor:'#233DFF', padding:'1px 39px', textTransform:'capitalize', outline:'none', borderRadius:'5px', color:'#ffffff', lineHeight:'1.75', border:'none', fontWeight:'600'}} onClick={() => editAction(gate, state)}>Run</button>,
                        }
                    ]
                )
                break;
            case GATE_STATES[2]:
                prompt(`Do you want to pause this experiment?`,
                <ul style={{paddingLeft: "1.2rem", color: "#4C4F53"}}>
                    <li style={{wordBreak:"break-all"}}>{name} will not make any more API calls after pausing.</li>
                    <li>When a gate is paused, variations can be added but not deleted.</li>
                </ul>,
                    [
                        {
                            label: <Button className="btn btn-success mx-3" style={{background: "#ffffff" , border: "1px solid #D5D9FE", color:"#233dff", textTransform: "capitalize", padding: "2px 36px", boxShadow:"none"}}>Close</Button>,
                        },
                        {
                            label: <Button variant="outlined" className="btn btn-success mx-3" onClick={() => editAction(gate, state)} style={{boxShadow:"none", backgroundColor: "#233dff" ,border: "1px solid #233dff !important", color:"#ffffff", textTransform: "capitalize", padding: "2px 40px", fontWeight: 600, height:"100%"}}>Pause</Button>,
                        }
                    ]
                )
                break;
            case GATE_STATES[3]:
                prompt(`Are you sure you want to archive this experiment?`,
                    <>
                       <ul style={{paddingLeft: "1.2rem", color: "#4C4F53"}}>
                            <li style={{wordBreak:"break-all"}}>{name} will be archived and cannot be reused when stopped.</li>
                            <li>If you want to pause the experiment, set the Traffic Allocation to zero percent.</li>
                        </ul>
                        <h6 className="text-black my-3" style={{fontWeight: "500", fontSize: "11px"}}>Type ARCHIVE in the box below then click on “Confirm” to stop and archived the experiment.</h6>
                        <input Input className="login__input mt-2 mb-2" ref={inputRef} placeholder="" />
                        <p style={{fontWeight: "400", fontSize: "12px", color: "#FE5749", paddingLeft:"15px"}} ref={archiveMsgRef} className="archive__msg">Please type ARCHIVE.</p>
                        <p style={{fontWeight: "400", fontSize: "12px", color: "#FE5749", paddingLeft:"15px"}} ref={blankMsgRef} className="archiveBlank__msg">This field is required.</p>

                    </>,
                    [
                        {
                            label: <Button className="btn btn-success mx-3" style={{background: "#ffffff" , border: "1px solid #D5D9FE", color:"#233dff", textTransform: "capitalize", padding: "2px 36px", boxShadow:"none"}}>Close</Button>,
                        },
                        {
                            label: <Button variant="outlined" className="btn btn-success mx-3" onClick={() => archiveAction(gate, state)} style={{boxShadow:"none", backgroundColor: "#233dff" ,border: "1px solid #233dff !important", color:"#ffffff", textTransform: "capitalize", padding: "2px 36px", fontWeight: 600, height:"100%"}}>Confirm</Button>,
                            dontClose: true,
                        }
                    ]
                )
                break;

            default:
                editAction(gate, state)
                break;
        }
    }

    const archiveAction = (gate, state) => {
        if (inputRef.current.value === "ARCHIVE") {
            closePrompt()
            editAction(gate, state)
        }else if(inputRef.current.value ===""){
            inputRef.current.className += "  error ";
            blankMsgRef.current.classList?.remove('archiveBlank__msg')
            archiveMsgRef.current.classList?.add('archive__msg')
        }
         else {
            inputRef.current.className += "  error ";
            archiveMsgRef.current.classList?.remove('archive__msg')
            blankMsgRef.current.classList?.add('archiveBlank__msg')
        }
    }

    const emptyDrawer = () => {
        document.querySelector('body').classList.add('custom_animation_show');
    }
    const openDrawerFunctions = (item) => {
        setSelectedGate(item);
        setEditGate(true);
        setShowSideDrawer(true);
        setTimeout(() => {
            emptyDrawer();
        }, 300);
    }

    const editAction = (gate, state) => {
        setLoading(true)
        const { id, name } = gate
        const payload = {
            state: state
        }
        new GatesService().updateGate(id, payload).then(response => {
            if (state === GATE_STATES[1]) {
                return history.push({
                    pathname: '/dashboard/' + state,
                    state: { msg: `${name} is running`, type: "success" }
                })
            }
            history.push({
                pathname: '/dashboard/' + state,
                state: { msg: `${name} has been ${state} successfully.`, type: state === GATE_STATES[1] ? "success" : "" }
            })
        }).catch(error => {
            console.error(error);
        })
    }

    const handleWrongUrl =()=>{
        setCheckUrl(false)
        history.push('/dashboard')
    }

 
    return (
        <>

            <Header />
            <div className="dashboard">
                <div className="dashboard__body " id="dashBody">
                    <Div2>
                        <div className="dashboard__body__header d-flex align-items-center justify-content-between">
                            <div className="dashboard__body__header-dropdown">
                            <CustomSelect value={tab} onChange={handleChange}>
                                <StyledOption disabled={totalData() === 0} value={'all'}>All Status ({totalData()})</StyledOption>
                                <StyledOption disabled={indiCounting.draft === undefined} value={'draft'}>Draft ({indiCounting.draft !== undefined ? indiCounting.draft : 0})</StyledOption>
                                <StyledOption disabled={indiCounting.running === undefined} value={'running'}>Running ({indiCounting.running !== undefined ? indiCounting.running: 0})</StyledOption>
                                <StyledOption disabled={indiCounting.paused === undefined} value={'paused'}>Pause ({indiCounting.paused !== undefined ? indiCounting.paused : 0})</StyledOption>
                                <StyledOption disabled={indiCounting.archived === undefined} value={'archived'}>Archive ({indiCounting.archived !== undefined ? indiCounting.archived : 0})</StyledOption>
                            </CustomSelect>
                            </div>
                            <Button className="new__gate"
                                variant="contained"
                                onClick={() => {
                                    setOpen(true)
                                }}
                            >
                                Create new gate
                            </Button>
                        </div>

                        <div className="dashboard__body__list">
                            {/* <div className="tab__header">
                                <Tabs
                                    value={tab}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                    TabIndicatorProps={{
                                        style: {
                                            display: "none",
                                        },
                                    }}
                                >
                                    <Tab label={
                                        <div className={`tab__header__item ${(tab === "all") ? 'active' : ''}`}>ALL({gateno})</div>
                                    }
                                        value="all"
                                    />
                                    <Tab label={
                                        <div className={`tab__header__item ${(tab === "draft") ? 'active' : ''}`}>
                                            <div className="tab__header__icon">
                                                <DraftIcon />
                                            </div>
                                            DRAFT({draft})
                                        </div>
                                    }
                                        value="draft"
                                    />
                                    <Tab label={
                                        <div className={`tab__header__item ${(tab === "running") ? 'active' : ''}`}>
                                            <div className="tab__header__icon">
                                                <RunningIcon />
                                            </div>
                                            RUNNING({running})
                                        </div>
                                    }
                                        value="running"
                                    />
                                    <Tab label={
                                        <div className={`tab__header__item ${(tab === "paused") ? 'active' : ''}`}>
                                            <div className="tab__header__icon">
                                                <BreakIcon />
                                            </div>
                                            Pause({pause})
                                        </div>
                                    }
                                        value="paused"
                                    />
                                    <Tab label={
                                        <div className={`tab__header__item ${(tab === "archived") ? 'active' : ''}`} onClick={() => setTab(4)}>
                                            <div className="tab__header__icon">
                                                <ArchivedIcon />
                                            </div>
                                            ARCHIVED({archived})
                                        </div>
                                    }
                                        value="archived"
                                    />
                                </Tabs>

                            </div> */}
                            {checkUrl ?
                            <div className="table__wrongUrl__container">
                                <Table responsive className="gates__list__body-table wrongUrl">
                                <thead className='body__header wrongUrl'>
                                    <tr>
                                    {WrongUrlCols.map((item) => <th key={item.id} className="gates__list__body-table-header wrongUrl">{item.value} <span style={{cursor:'pointer'}}>{item.icon}</span></th>)}
                                        <th className="gates__list__body-table-header wrongUrl"></th>
                                    </tr>
                                </thead>
                                <tbody className='table__body wrongUrl'>
                                    <tr>

                                    <th colSpan={7}><div>The requested URL was not found on this server. </div>
                                    <div>Return to <a onClick={handleWrongUrl}> Dashboard. </a></div>
                                    </th>
                                    </tr>
                                </tbody>
                                </Table>  
                            </div>
                             :
                            <>
                            {
                                loading
                                    ?
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '5em' }}>
                                        <CircularProgress color="inherit" />
                                    </Box>
                                    :
                                    <div className="tab__body">
                                        <TabPanel value={tab} index={"all"}>
                                            <GatesList onEdit={onEdit} state={tab} gate={gates} paginateGates={paginateGates} handlePageClick={handlePageClick} setOpen={setOpen} ReactPaginate={reactPaginate} openCustomer={(item) => {
                                                openDrawerFunctions(item);
                                            }} />
                                        </TabPanel>
                                        <TabPanel value={tab} index={"draft"}>
                                            <GatesList onEdit={onEdit} state={tab} gate={gates} paginateGates={paginateGates} handlePageClick={handlePageClick} setOpen={setOpen} ReactPaginate={reactPaginate} openCustomer={(item) => {
                                                openDrawerFunctions(item);
                                            }} />
                                        </TabPanel>
                                        <TabPanel value={tab} index={"running"}>
                                            <GatesList onEdit={onEdit} state={tab} gate={gates} paginateGates={paginateGates} handlePageClick={handlePageClick} setOpen={setOpen} ReactPaginate={reactPaginate} openCustomer={(item) => {
                                                openDrawerFunctions(item);
                                            }} />
                                        </TabPanel>
                                        <TabPanel value={tab} index={"paused"}>
                                            <GatesList onEdit={onEdit} state={tab} gate={gates} paginateGates={paginateGates} handlePageClick={handlePageClick} setOpen={setOpen} ReactPaginate={reactPaginate} openCustomer={(item) => {
                                                openDrawerFunctions(item);
                                            }} />
                                        </TabPanel>
                                        <TabPanel value={tab} index={"archived"}>
                                            <GatesList onEdit={onEdit} state={tab} gate={gates} paginateGates={paginateGates} handlePageClick={handlePageClick} setOpen={setOpen} ReactPaginate={reactPaginate} openCustomer={(item) => {
                                                openDrawerFunctions(item);
                                            }} />
                                        </TabPanel>
                                    </div>
                            }
                            </>
                        }
                        </div>
                        <div className="dashboard__success__message">
                        {
                            state.successMsg
                            &&
                            <div className={`dashboard__body__success ${state.successType ? 'success' : ''}`}>
                                <div className="header__msg">
                                <div className="success_icon_msg">Success</div>
                                <div className="success-close" onClick={handleSuccess}>close</div>
                                </div>
                                <p className="success-msg">{state.successMsg} </p>
                            </div>
                        }
                        </div>
                    </Div2>
                </div>
                {showSideDrawer ? <GateForm apiData={apiData}  open={showSideDrawer} selectedGate={selectedGate} editGate={editGate} closeDrawer={() => {
                    document.querySelector('body').classList.remove('custom_animation_show');
                    setTimeout(() => {
                        setShowSideDrawer(false);
                        setEditGate(false);
                        setSelectedGate(null);
                    }, 100);
                    let state = params.state !== 'all' ? indiCounting.draft !== undefined ? params.state || 'draft' : params.state || 'all' : 'all'
                    fetchGates({filter:  { "q[state_eq]": state === "all" ? "" : state, per_page:15}});
                }} onCreateGate={() => {
                    fetchGates();
                    setShowSideDrawer(false);
                }} /> : null}
            </div>
            <Dialog
                open={open} onClose={handleClose}
                titleProps={
                    <div className="create__gate dashboard">
                        <span className="create__gate__title-text">Create new gate</span>
                    </div>
                }
                loading={loading}
            >
                <Box className="create__gate__body pb-2" width="sm">
                    {sameNameError ? 
                         <p className={`${sameNameError ? 'login__input__error-display' : 'login__input__error-hide'}`}
                         >
                             {sameNameError}
                         </p> :
                    <>
                    {indiError || variationindiError ? '' :
                    <p
                    className={`${(state.nameError || state.variationError) ? 'login__input__error-display' : 'login__input__error-hide'}`}
                    >
                        {(state.nameError || state.variationError)}
                    </p>
                    }
                    </>
                    }
                    <form onSubmit={(e) => e.preventDefault()}>
                        <TextField
                            variant="standard"
                            type="text"
                            id="name"
                            inputProps={{ maxLength: 105 }}
                            value={state.name.trimStart()}
                            onChange={handleState}
                            placeholder="Gate Name"
                            className={`input ${state.nameError ? 'error' : ''}`}
                            hiddenLabel
                            size="small"
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <span className={`create__gate__body__label char ${indiError ? "error-display" : ""}`}>{state.nameCount}/105 <span className={`${indiError}` ? 'error-display' : 'login__input__error-hide'}> - {indiError}</span></span>
                        <TextField
                            hiddenLabel
                            type="number"
                            className={`input ${state.variationError ? 'error' : ''}`}
                            id="variation"
                            inputProps={{ min: 0}}
                            variant="standard"
                            value={state.variation}
                            onChange={handleState}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    onCreate();
                                }
                                
                            }}
                            onKeyDown={blockInvalidChar}
                            placeholder="Number of variation"
                            size="small"
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <p className={`create__gate__body__label number ${variationindiError ? "error-display" : ""}`}>Minimum is 2 - maximum is 10  {state.variation > 0 ? null : variationindiError  ? <span>{variationindiError}</span> : null}</p>
                        <div className="create__gate__body__footer pt-3">
                            <Button variant="outlined" className="close__btn" onClick={handleClose} >Close</Button>
                            <Button className="update__btn" onClick={onCreate}>
                                Create
                            </Button>
                        </div>
                    </form>
                </Box>
            </Dialog>

            {/* <Footer /> */}
        </>
    )
}

const Div2 = styled.div`
    width: 100%;
    padding: 55px;
    margin: 0;
    background: transparent;
`
const DBOX = styled.div`
    &:hover{
        cursor:pointer;
    }
`
export default Gates;
