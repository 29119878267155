import React from "react";
import { Link } from "react-router-dom";

const NotificationBar = ({planUsed, show}) => {
    const [showBar, setShowBar] = React.useState(show)
    const handleClose=(e)=>{
        e.preventDefault();
        setShowBar(false)
        localStorage.setItem('seenNotification', '1')
    }
    const bgColor = () => {
        if (planUsed === 100) {
            return 'danger'
        }
        return 'primary'
    }
    return (
        <>
            { showBar && planUsed >= 70 && localStorage.getItem('seenNotification') !== '1' &&
                <div className={`bg-${bgColor()} w-100`} style={{height: "40px"}}>
                    <div className="p-1 text-center">
                        <small className="text-white fw-bold">
                            You have used { planUsed }% of your current plan
                            {' '}
                            <Link
                                to='#'
                                className='text-white'
                                onClick={(e) => {
                                    window.location.href = 'mailto:sales@accor.com';
                                    e.preventDefault();
                                }}
                            >
                                upgrade now
                            </Link>
                        </small>
                        <span className="float-end">
                            <Link to='#' className="text-white fw-bold" onClick={(e)=>handleClose(e)}>close</Link>
                        </span>
                    </div>
                </div>
            }
        </>
    );
};

export default NotificationBar;

NotificationBar.defaultProps = {
    color: 'primary',
    planUsed: 0
};
