import React, { useEffect, useState, useRef } from 'react';
import './index.scss';
import { CircularProgress, Typography, IconButton, Grid, Input as MUIInput } from '@mui/material';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
import Header from '../shared/header';
// import Footer from '../shared/footer';
import Button from '../shared/button'
import CredentialsService from '../../services/http/credentialsService';
import CredentialsList from '../list/credentials';
import styled from "styled-components"
import { ConfirmationDialog } from '../shared/dialog';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import ApiIntegration from '../apiIntegration';
import { closePrompt, prompt } from '../shared/prompt';
import { Pencil } from '../../assets/icons';
import { getValue } from '../../services/storageService';
import Dialog from '../shared/dialog'
import { checkPassword } from '../../utils';
import { PasswordInput } from '../shared/input';

const InputWrapper = styled(Box)({
    width: 240,
    marginRight: 36,
    height: 27,
    paddingLeft: "0.5rem",
    paddingRight: "0.2rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fafafa',
    '& .MuiInput-root': {
        fontSize: 13,
        width: "100%",
    },
    '&::first-child':{
        backgroundColor: 'transparent'
    }
});

const KontoroHeading = styled(Typography)({
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "inherit",
    // letterSpacing: "4.65px",
});

const KontoroMessage = styled(Typography)({
    padding: "7px 11px 9px 10px",
    width: "322px",
    wordBreak: "inherit",
    borderRadius: "0",
    borderLeft: "4px solid #00B481",
    boxShadow: "0px 4px 4px rgba(196, 196, 196, 0.57)",
    backgroundColor: "#212936",
    position: "fixed",
    bottom: "1.5000em",
    left: "2.5000em",
    zIndex:"1500",
    '& .error': {
        backgroundColor: "rgba(231, 13, 13, 0.19)",
    },
    '& .success-msg': {
        color: "#0A0E13",
        marginRight: "0.75rem",
    },
    '& .success-close': {
        color: "#606367",
        textDecoration: "underline",
        cursor: "pointer",
    },
});

const CredentialList = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false)
    const [passLoading, setPassLoading] = useState(false)
    const fetchData = ({ onload }) => {
        setLoading(onload)
        new CredentialsService().getCredentials().then(response => {
            const res = response.data.value;
            setData(res);
            setLoading(false)
        }).catch(error => {
            console.log(error);
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchData({ onload: true });
    }, []);

    const [state, setState] = useState({
        successMsg: "",
    })

    const handleSuccess = () => {
        setState(prevState => ({
            ...prevState,
            successMsg: "",
        }))
    }

    const onCreate = (updated) => {
        new CredentialsService().createCredentials().then(response => {
            const res = response.data.value
            // fetchData({ onload: false })
            setData(res)
            setState(prevState => ({
                ...prevState,
                successMsg: `API Credentials have been ${updated ? 'updated' : 'created'} successfully.`
            }))
            setRefresh(false)
        }).catch(error => {
            console.error(error);
            setRefresh(false)
        })
    }

    const [refresh, setRefresh] = useState(false)

    const inputRef = useRef();
    const updateMsgRef = useRef();
    const updateBlankRef = useRef();
    const onRefresh = () => {
        prompt(`Are you sure you want to refresh the API Credential?`,
            <>
                <p style={{color: "#4C4F53", fontWeight: "400", fontSize: "12px"}}>If you continue your old API Credentials will stop working.</p>
                <h6 className="text-black my-3" style={{fontWeight: "500", fontSize: "13px"}}>Type UPDATE in the box below then click "Confirm" to refresh.</h6>
                <input Input className="login__input mt-2 mb-2" ref={inputRef} onChange={(e) => e.target.classList.remove("error")} placeholder="" />
                <p style={{fontWeight: "400" ,fontSize: "12px", color: "#fe5749" , marginLeft: "1rem"}} ref={updateMsgRef} className='update__error'>Please type UPDATE.</p>
                <p style={{fontWeight: "400" ,fontSize: "12px", color: "#fe5749" , marginLeft: "1rem"}} ref={updateBlankRef} className='updateBlank__error'>This field is required.</p>
            </>,
            [
                {
                    label: <Button variant="outlined" className="btn btn-success mx-3" style={{boxShadow:"none",fontSize:"14px", letterSpacing:"0.25px", backgroundColor: "#ffffff" ,border: "1px solid #D5D9FE", color:"#233dff", textTransform: "capitalize", padding: "3px 30px", fontWeight: 600, height:"100%"}} onClick={() => {
                        if (inputRef.current.value === "UPDATE") {
                            closePrompt()
                            onCreate('Updated')
                        }else if(inputRef.current.value ===""){
                            inputRef.current.className += "  error ";
                            updateBlankRef.current.classList?.remove('updateBlank__error')
                            updateMsgRef.current.classList?.add('update__error')
                        }
                         else {
                            // inputRef.current.classList?.add("error");
                            // inputRef.current?.previousSibling?.classList?.remove("text-black");
                            // inputRef.current?.previousSibling?.classList?.add("text-danger");
                            inputRef.current.className += "  error ";
                            updateBlankRef.current.classList?.add('updateBlank__error')
                            updateMsgRef.current.classList?.remove('update__error')
                        }
                    }} >Confirm</Button>,
                    dontClose: true,
                },
                {
                    label: <Button className="btn btn-success mx-3" style={{background: "#233dff" , border: "1px solid #D5D9FE", color:"#ffffff", textTransform: "capitalize", padding: "3px 30px", boxShadow: "none"}}>Close</Button>,
                }
            ]
        )
    }

    const initialValue = {
        password: "",
        passwordError: "",
        currentPassword: "",
        currentPasswordError: "",
        successMsg: "",
        successType: "",
        apiError: "",
    }
    const [values, setValues] = useState(initialValue)
    const [open, setOpen] = React.useState(false)
    const handleClose = () => { setValues(initialValue); setOpen(false); }

    const handleState = (e) => {
        const { id, value } = e.target;
        setValues(prevState => ({
            ...prevState,
            [id]: value,
            passwordError: "",
            currentPasswordError: "",
            apiError: "",
        }))
    }

    const isValidForm = () => {
        let res = true
        // const format = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
        if (values.currentPassword === "") {
            setValues(prevState => ({
                ...prevState,
                currentPasswordError: "This is required.",
                passwordError:  values.password === "" ? "These fields are required. " : prevState.passwordError,
            }))
            return false
        }

        if (values.currentPassword === values.password) {
            setValues(prevState => ({
                ...prevState,
                currentPasswordError: "Old password should not be same as current Password",
                passwordError:  values.password === "" ? "These fields are required. " : prevState.passwordError,
            }))
            return false
        }
        // else {
        //    let err = checkPassword(values.currentPassword, 'Current Password')
        //    if(err !== true) {
        //        return setValues(prevState => ({
        //            ...prevState,
        //            currentPasswordError: err,
        //        }))
        //    }
        //    // res = false
        // }

        if (values.password === "") {
            setValues(prevState => ({
                ...prevState,
                passwordError: "This is required.",
            }))
            res = false
        } else {
            let err = checkPassword(values.password, 'New Password')
            if(err !== true) {
                setValues(prevState => ({
                    ...prevState,
                    passwordError: err,
                }))
                res = false
            }
        }
        return res
    }

    const onConfirm = () => {
        if (!isValidForm()) return
        setPassLoading(true)
        const payload = {
            password: values.password,
            old_password: values.currentPassword
        }
        new CredentialsService().updateCredentials(payload).then(response => {
            setValues(prevState => ({
                ...prevState,
                password: "",
                currentPassword: "",
            }))
            setState(prevState => ({
                ...prevState,
                successMsg: `Password has been updated with success.`,
            }))
            handleClose()
            setPassLoading(false)
        }).catch(error => {
            console.error(error.response.data);
            setValues(prevState => ({
                ...prevState,
                passwordError: "",
                currentPasswordError: error.response.data.message
            }))
            setPassLoading(false)
        })
    }

    const msgRef = useRef(null)
    useEffect(() => {
        if (state.successMsg) {
            setTimeout(() => {
                handleSuccess()
            }, 5000);
        }
        if(msgRef.current){
            msgRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.successMsg])

    return (
        <>
            <Header />
            <div className="credential">
                <div style={{ padding: "0" }} className="credential__body">
                    <Div2 className='setting__container'>
                        {
                            state.successMsg
                            &&
                            <KontoroMessage ref={msgRef}>
                                <div className="refresh__msg">
                                <div className="header__msg">
                                <div className="success_icon_msg">Success</div>
                                <div className="success-close" onClick={handleSuccess}>close</div>
                                </div>
                                <p className="success-msg">{state.successMsg} </p>
                                </div>
                            </KontoroMessage>
                        }
                        <KontoroHeading style={{marginBottom: "22px"}}><span className='dark'>Settings</span></KontoroHeading>
                        <div className="setting__bottom__container">
                        <div className="credential__body__header d-flex  align-items-center justify-content-between">
                        </div>
                        <div className="subHeader">
                            Account Settings
                        </div>
                        <div className="subPara">
                        Change your password here.
                        </div>
                        <Grid container style={{alignItems:"center"}} className="user__details">
                            <span>Email</span>
                            <InputWrapper className="email" style={{backgroundColor:"transparent"}}>
                                <MUIInput disableUnderline defaultValue={getValue('email')} readOnly type="text" placeholder='Email'  />
                                {/* <IconButton>
                                    <Pencil />
                                </IconButton> */}
                            </InputWrapper>
                            <span>Password</span>
                            <InputWrapper>
                                <MUIInput disableUnderline readOnly defaultValue="**********" onFocus={() => false} type="password" placeholder='Password' className="password" />
                                <IconButton onClick={() => setOpen(true)}>
                                    <Pencil />
                                </IconButton>
                            </InputWrapper>
                        </Grid>
                        {/* <div className="credential__body__header d-flex align-items-center justify-content-between"> */}
                        <div className="credential__body__header">
                            <KontoroHeading><span className='dark'>API Credential</span></KontoroHeading>
                            <div className="subPara" style={{marginBottom: "21px"}}>
                            This key is used to secure API.
                            </div> 
                            {
                                (Object.keys(data).length === 0)
                                &&
                                <Button className="btn btn-primary"
                                    variant="contained"
                                    onClick={onCreate}
                                >
                                    Create Credential
                                </Button>
                            }
                        </div>
                        <div style={{ marginBottom: "40px" }} className="credential__body__list">
                            {
                                loading ?
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '5em' }}>
                                        <CircularProgress color="inherit" />
                                    </Box>
                                    :
                                    <CredentialsList data={data} onCreate={onCreate} onRefresh={onRefresh} />
                            }
                        </div>
                        <KontoroHeading><span className='dark'>API Integration</span></KontoroHeading>
                        <div className="subPara">
                            This key is used to secure API.
                            </div> 
                        <ApiIntegration secret={data.secret} apiKey={data.api_key} />
                        </div>
                    </Div2>
                </div>
            </div>

            {/* <Footer /> */}
            {
                refresh && <ConfirmationDialog
                    title="Are you sure you want to refresh the API Credential?"
                    titleClass="pb-0"
                    msg={`Your old API Credential will not work.`}
                    cta={
                        <div className="text-center">
                            <Button variant="outlined" className="btn-primar-outlined mx-3" onClick={() => onCreate('Updated')}>REFRESH</Button>
                            <Button onClick={() => setRefresh(false)}>CLOSE</Button>
                        </div>
                    }
                />
            }
            <Dialog
                open={open} onClose={handleClose}
                titleProps={
                    <div className="create__gate" style={{padding: "0 12px"}}>
                        <span className="create__gate__title-text px-1">Change your password</span>
                    </div>
                }
                loading={passLoading}
            >
                <Box className="create__gate__body pb-2" width="sm">
                    <p
                        className={`mx-1 ${(values.passwordError || values.currentPasswordError || values.apiError) ? 'login__input__error-display' : 'login__input__error-hide'}`}
                    >
                        {(values.passwordError || values.currentPasswordError || values.apiError)}
                    </p>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <PasswordInput 
                            className={`input ${values.currentPasswordError || values.apiError ? 'error' : ''}`}
                            id="currentPassword"
                            value={values.currentPassword}
                            onChange={handleState}
                            placeholder="Current password"
                            size="small"
                            sx={{ mb: 2, mx: 0.5, width: "97.5% !important" }}
                        />
                        <PasswordInput
                            hiddenLabel
                            variant="standard"
                            className={`input ${values.passwordError || values.apiError ? 'error' : ''}`}
                            id="password"
                            value={values.password}
                            onChange={handleState}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    onConfirm();
                                }
                            }}
                            placeholder="New password"
                            size="small"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            sx={{ mb: 0, mx: 0.5, width: "97.5% !important" }}
                        />
                        <Typography sx={{ fontSize: 9, pt: 1.5, pl:1 }} >Use 8 or more characters with a mix of letters, numbers & symbols (!, @, #, $, %, ^, &, *).</Typography>
                        <div className="create__gate__body__footer pt-3">
                            <Button variant="outlined" className="btn btn-success" style={{boxShadow:"none",fontSize:"14px", letterSpacing:"0.25px", backgroundColor: "#ffffff" ,border: "1px solid #D5D9FE", color:"#233dff", textTransform: "capitalize", padding: "4px 30px", fontWeight: 500, height:"100%", margin: "0",marginRight:"15px", lineHeight: "inherit"}} onClick={handleClose} >Close</Button>
                            <Button className="btn btn-success" style={{boxShadow:"none",letterSpacing: "0.25px", backgroundColor: "#233dff", borderRadius: "5px" ,border: "1px solid #233dff", color:"#ffffff", textTransform: "capitalize", padding: "2px 30px", fontWeight: 600, height:"100%"}} onClick={onConfirm}>
                                Update
                            </Button>
                        </div>
                    </form>
                </Box>
            </Dialog>
        </>
    )
}

export default CredentialList;
const DBOX = styled.div`
    &:hover{
        cursor:pointer;
    }
`
const Div2 = styled.div`
    width: 100%;
    padding: 45px 55px;
    margin: 0;
    background: transparent;
`
