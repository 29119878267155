import BaseService from "./baseService";

class GatesService extends BaseService {
    createGate(payload) {
        return this.post('/api/gates', payload);
    }
    fetchGates(params) {
        return this.request({ "url": '/api/gates', params })
    }
    updateGate(id, payload) {
        return this.put(`/api/gates/${id}`, payload);
    }
    fetchGate(id,params){
        return this.request({"url": `/api/gates/${id}/visitors_count?${params}`})
    }
    fetchGateCreation(id){
        return this.request({"url": `/api/gates/${id}`})
    }
    logScreen(bussinessId, params){
        return this.request({"url": `/api/users/${bussinessId}/activity`, params})
    }
}

export default GatesService;
