import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import '../layouts/AuthLayout.scss';
import "../talkToRepresentative/talkToRepresentative.scss"
import logo from "../../assets/images/Logo.png";
import HubspotForm from '../hubSpotForm';
import { CircularProgress, Box} from '@mui/material';

const TalkRepresentative = () => {
  const history = useHistory();
  const [loading, setLoading] =useState(false)


    window.addEventListener('message', event => {
      if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onBeforeFormInit') {
        setLoading(false)
      }
      if(event.data.type === 'hsFormCallback' &&  event.data.eventName === 'onFormSubmitted') {
          return  history.push({
            pathname: '/login',
            state: { welcome: "successfully" }
          })
      }
    });
        
  useEffect(()=>{
    setLoading(true)
  }, [])

  return (
    <>
      <div className="representative">
        <div className="login__header">
        <span>
          <img src={logo} alt="logo" /> Kontoro labs
        </span>
        </div>
        <div className="login__title">
          <div className="login__title-text">
          How can we reach you ? 
          </div>
        </div>
        <div className="representative__container">
          {loading ? 
           <Box sx={{ display: 'flex', justifyContent: 'center', minHeight:"450px", alignItems: "center"}}>            
             <CircularProgress color="inherit" />
          </Box> :
          <HubspotForm idName={"representative__container__content"} portalId={"21920650"} formId={"1da76197-12ba-48bf-8ec2-01f804d40e8f"} />
        }
        </div>
        <div className="back__login__cta" >
                <Link to="/login" className="login__link">Back to sign in page</Link>
              </div>
        </div>
    </>
  )
};

export default TalkRepresentative;
