import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../login';
import ChangePassword from '../changePassword';
import SignUp from '../signUp';
import ApiTest from '../ApiTest';
import Gates from '../gates';
import Plans from '../plans';
import AuthLayoutRoute from '../layouts/AuthLayout';
import MainLayoutRoute from '../layouts/MainLayout';
import CredentialList from '../credentialList';
// import ForgotPassword from '../forgotPassword';
// import ResetPassword from '../resetPassword';
import OpenLayoutRoute from '../layouts/OpenLayout';
import TalkRepresentative from '../talkToRepresentative';
import PageNotFound from '../404';
import LogDetails from '../logDetails';
import BusinessAccountSignup from '../BusinessAccountSignup';
import PaymentSuccess from '../BusinessAccountSignup/PaymentSuccess';
import SubscriptionDetails from '../subscriptions';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <AuthLayoutRoute path='/signup' component={BusinessAccountSignup} />
      <AuthLayoutRoute path='/payment-success' component={PaymentSuccess} />
      <AuthLayoutRoute path="/login" component={Login} />
      <AuthLayoutRoute path="/talktorepresentative" component={TalkRepresentative} />
      <AuthLayoutRoute exact path={'/change-password'} component={ChangePassword} />
      <AuthLayoutRoute exact path={'/signup'} component={SignUp} />
      <MainLayoutRoute exact path={'/dashboard'} component={Gates} />
      <MainLayoutRoute exact path={'/dashboard/:state'} component={Gates} />
      <MainLayoutRoute exact path={'/api-list'} component={CredentialList} />
      <MainLayoutRoute exact path={'/log-details'} component={LogDetails} />
      <MainLayoutRoute exact path={'/plans'} component={Plans} />
      <MainLayoutRoute exact path={'/subscription-details'} component={SubscriptionDetails} />
      <OpenLayoutRoute path="/api-test" component={ApiTest} />

      <Route component={PageNotFound} />

    </Switch>
  )
};

export default Routes;
