import React from 'react'
import { Route } from 'react-router-dom';
import './AuthLayout.scss'

const OpenLayout = ({ children }) => {
    return (
        <div className="authContainer">
            {children}
        </div>
    )
}

const OpenLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <OpenLayout>
                <Component {...matchProps} />
            </OpenLayout>
        )} />
    )
};


export default OpenLayoutRoute
