import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setValue } from '../../services/storageService';
import Loader from '../shared/loader';// Create a Loader component or use any spinner/loader you prefer

const PaymentSuccess = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    const ownerEmail = queryParams.get('owner_email');
    const baseUrl = process.env.REACT_APP_BASE_API_URL;

    const confirmPayment = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/subscriptions/confirm_payment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ session_id: sessionId, owner_email: ownerEmail })
        });

        const data = await response.json();
        if (response.ok) {
          toast.success('Payment confirmed and account created successfully');
          setValue('token', data.data.token); // Adjust based on your token structure
          history.push('/dashboard');
        } else {
          throw new Error(data.error);
        }
      } catch (error) {
        toast.error(error.message || 'Failed to confirm payment. Please try again.');
        setLoading(false); // Allow user to retry or navigate back
      }
    };

    confirmPayment();
  }, [history, location.search]);

  return (
    <div className="payment-success">
      {loading ? (
        <Loader /> // Your loader component here
      ) : (
        <div className="payment-success__message">
          <p>Failed to confirm payment. Please try again.</p>
          <button onClick={() => history.push('/signup')}>Go Back to Signup</button>
        </div>
      )}
    </div>
  );
};

export default PaymentSuccess;
