import React, { useLayoutEffect } from 'react'
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { getValue } from '../../services/storageService';
import Prompt from '../shared/prompt';
import './MainLayout.scss'

const MainLayout = ({ children }) => {
    const history = useHistory();
    useLayoutEffect(() => {
        if (!getValue('token')) history.push('/login')
    }, [history])
    return (
        <>
            <Prompt />
            <div className="main__wrapper">
                {children}
            </div>
        </>
    )
}

const MainLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <MainLayout>
                <Component {...matchProps} />
            </MainLayout>
        )} />
    )
};


export default MainLayoutRoute
