import BaseService from "./baseService";

class LoginService extends BaseService {
  login(payload) {
    return this.post('/api/users/login', payload);
  }
  forgotPassword(payload) {
    return this.post('/api/user_passwords/forgot_password', payload);
  }
  resetPassword(payload) {
    return this.post('/api/user_passwords/reset_password', payload);
  }
  googlelogin(response) {
    return this.post('/auth/google_oauth2/callback', response);
  }
  register(payload) {
      return this.post('/api/users', payload);
  }
  businessAccounts(payload) {
      return this.get('/api/business_accounts');
  }
  usersBusinessAccounts(email) {
      return this.get(`/api/users/business_account_list?email=${email}`);
  }

}

export default LoginService;
